import axios from '../classes/Axios';
import _ from 'lodash';

const productStore = {
    namespaced: true,
    state: {
        loaded: false,
        products: {},
        additionalInfo: {}
    },
    mutations: {
        setAdditionalInfo(state, setObj) {
            if (_.isEmpty(state.additionalInfo[setObj.productId])) {
                state.additionalInfo[setObj.productId] = {};
            }

            state.additionalInfo[setObj.productId] = setObj.additionalInfo;
        },
        set(state, setObj) {
            if (_.isEmpty(state.products[setObj.orderId])) {
                state.products[setObj.orderId] = {};
            }

            state.products[setObj.orderId] = setObj.products
        },
        add(state, setObj) {
            if (_.isEmpty(state.products[setObj.orderId])) {
                state.products[setObj.orderId] = {};
            }

            state.products[setObj.orderId][setObj.product.ProductId] = setObj.product
        },
        delete(state, setObj) {
            delete state.products[setObj.orderId][setObj.productId];
        },
        setLoaded(state, bool) {
            state.loaded = bool;
        },
        clear(state) {
            this.additionalInfo = {};
            state.products = {};
            state.loaded = false;
        }
    },
    actions: {
        getAll(storeArgObj, orderId) {
            return new Promise((resolve) => {
                if (storeArgObj.state.products[orderId]) {
                    resolve(storeArgObj.state.products[orderId]);
                } else {
                    let url = new URL(storeArgObj.rootState.portalUrl + 'product/getAll');
                    url.searchParams.set('orderId', orderId);
                    axios.get(url.href).then((data) => {
                        let products = data.data.data.products;
                        // leeg resultaat
                        if (_.isEmpty(products)) {
                            products = {};
                        }

                        let setObj = {
                            orderId: orderId,
                            products: products
                        };
                        storeArgObj.commit('set', setObj);
                        storeArgObj.commit('setLoaded', true);
                        resolve(products);
                    });
                }
            });
        },
        getAdditionalInfo(storeArgObj, paramObj) {
            return new Promise((resolve) => {
                if (paramObj && paramObj.productId && storeArgObj.state.additionalInfo && storeArgObj.state.additionalInfo[paramObj.productId]) {
                    resolve(storeArgObj.state.additionalInfo[paramObj.productId]);
                } else {
                    let url = new URL(storeArgObj.rootState.portalUrl + 'product/getAdditionalInfo');
                    url.searchParams.set('merchantSetupId', paramObj.merchantSetupId);
                    if (typeof paramObj.productId !== 'undefined' && paramObj.productId !== null)
                        url.searchParams.set('productId', paramObj.productId);
                    if (typeof paramObj.articleGroupId !== 'undefined' && paramObj.articleGroupId !== null)
                        url.searchParams.set('articleGroupId', paramObj.articleGroupId);

                    axios.get(url.href).then((data) => {
                        let additionalInfo = data.data.data;
                        // leeg resultaat
                        if (_.isEmpty(additionalInfo)) {
                            additionalInfo = {};
                        }

                        let setObj = {
                            productId: paramObj.productId,
                            additionalInfo: additionalInfo
                        };
                        storeArgObj.commit('setAdditionalInfo', setObj);
                        resolve(additionalInfo);
                    });
                }
            });
        },
    }
};
export default productStore;
