import axios from '../classes/Axios';
import _ from 'lodash';

const headerStore = {
    namespaced: true,
    state: {
        logo: null,
        languages: null
    },
    mutations: {
        setAll(state, headerData) {
            state.logo = headerData.headerLogo;
            state.languages = headerData.languages;
        },
        clear(state) {
            state.logo = null;
            state.languages = null;
        }
    },
    actions: {
        get(storeArgObj) {
            return new Promise((resolve) => {
                if (storeArgObj.state.logo && storeArgObj.state.languages) {
                    let returnObj = {
                        logo: storeArgObj.state.logo,
                        languages: storeArgObj.state.languages
                    }
                    resolve(returnObj);
                } else {
                    let url = new URL(storeArgObj.rootState.portalUrl + 'app/getHeaderPageData');
                    axios.get(url.href).then((data) => {
                        let headerData = data.data.data;
                        if (_.isEmpty(headerData)) {
                            headerData = {};
                        }
                        storeArgObj.commit('setAll', headerData);
                        resolve(headerData);
                    });
                }
            });
        }
    }
};
export default headerStore;
