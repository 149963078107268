<template>
    <div>
        <div class="container-fluid overflow-x-hidden">
            <div class="row h-100">
                <div class="col-lg-6 block-left d-table h-100">
                    <div class="card card-body d-table-cell align-middle border-0">
                        <span class="simple-text">

                            <h1>
                                We're in maintenance mode
                            </h1>

                            <p>
                                The returns portal is currently unavailable.
                            </p>

                            <p>
                                {{ $store.state.portal.parameters.maintenanceModeCustomText }}
                            </p>
                        </span>
                    </div>
                </div>

                <div class="col-6 block-right">

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'MaintenancePage',
        data() {
            return {}
        },
        created() {

        },
        methods: {}
    }
</script>

<style>

</style>
