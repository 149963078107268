<template>
	<div>
		<div class="col-12 col-sm-6 mb-3" :class="isSmallDisplay ? '' : 'col-md-4'" v-for="(ca, key) in customAttributes" :key="key">
			<div :class="isSmallDisplay ? 'px-3 py-1' : ''">
				<label class="label--sm">
					{{ca.label}}
				</label>

				<span :class="isSmallDisplay ? 'font-sm' : 'input-read-text'">
					<template v-if="ca.type === 'date'">
						{{ getFormattedDate(ca.value) }}
					</template>

					<template v-else-if="ca.type === 'file' && ca.value.url && ca.value.label">
						<a :href="ca.value.url" target="_blank">
							{{ ca.value.label }}
						</a>
					</template>

					<template v-else>
						{{ ca.value }}
					</template>
				</span>
			</div>
		</div>
	</div>
</template>

<script>
    export default {
        components: {},
        props: [
            'customAttributes',
            'isSmallDisplay'
        ],
        data() {
            return {}
        },
        methods: {
            getFormattedDate(date) {
                let d = new Date(date);
                return d.getUTCFullYear() + '-' + ("0" + (d.getUTCMonth() + 1)).slice(-2) + '-' + ("0" + d.getUTCDate()).slice(-2);
            },
        },
        created() {

        },
    }
</script>

<style scoped>

</style>
