<template>
    <div class="page">
        <SspHeader activeMenuItem="create"/>

        <main class="main">
            <InstructionsSection contentBlockSection="AddProductsPage" progressBarCurrentStep="addProducts"/>

            <form action="#">
                <div class="container sm">
                    <label class="pb-3">
                        {{ $store.state.translations.translations['Select a product to return'] }}
                    </label>

                    <template v-if="params && params.articleSearchPerGroup">
                        <div class="accordion">
                            <div class="accordion-card">
                                <div class="accordion-card-header">
                                    <div class="accordion-card-header-td first">
                                        <b-button v-b-toggle="'productFeedArticleGroup'" class="btn-link">
                                            <i class="icon-plus"></i>
                                        </b-button>
                                    </div>

                                    <div class="accordion-card-header-td second">
                                        {{ $store.state.translations.translations['Product group'] }}
                                    </div>

                                    <div class="accordion-card-header-td third" v-if="selectedArticleGroup">
                                        {{ selectedArticleGroup }}
                                    </div>
                                </div>

                                <b-collapse id="productFeedArticleGroup" accordion="product-page" v-model="productPageAccordion['articleGroup']">
                                    <div class="collapse-body">
                                        <div class="card-body">
                                            <ProductFeedArticleGroup/>
                                        </div>
                                    </div>
                                </b-collapse>
                            </div>
                        </div>

                        <div class="accordion" v-if="params.articleSearchPerSubGroup">
                            <div class="accordion-card">
                                <div class="accordion-card-header">
                                    <div class="accordion-card-header-td first">
                                        <b-button v-b-toggle="'productFeedArticleSubGroup'" class="btn-link">
                                            <i class="icon-plus"></i>
                                        </b-button>
                                    </div>

                                    <div class="accordion-card-header-td second">
                                        {{ $store.state.translations.translations['Product sub group'] }}
                                    </div>

                                    <div class="accordion-card-header-td third" v-if="selectedArticleSubGroup">
                                        {{ selectedArticleSubGroup }}
                                    </div>
                                </div>

                                <b-collapse id="productFeedArticleSubGroup" accordion="product-page" v-model="productPageAccordion['articleSubGroup']">
                                    <div class="collapse-body" v-if="articleSubGroups && Object.keys(articleSubGroups).length > 0">
                                        <div class="card-body">
                                            <ProductFeedArticleGroup v-if="params.articleSearchPerSubGroup && articleSubGroups && Object.keys(articleSubGroups).length > 0" :article-parent-group-id="currentGroup"/>
                                        </div>
                                    </div>

                                    <div class="collapse-body" v-else-if="!currentGroup">
                                        <div class="card-body">
                                            {{ $store.state.translations.translations['Select an article group.'] }}
                                        </div>
                                    </div>

                                    <div class="collapse-body" v-else>
                                        <div class="card-body">
                                            {{ $store.state.translations.translations['No sub-groups available.'] }}
                                        </div>
                                    </div>
                                </b-collapse>
                            </div>
                        </div>

                        <div class="accordion">
                            <div class="accordion-card">
                                <div class="accordion-card-header">
                                    <div class="accordion-card-header-td first">
                                        <b-button v-b-toggle="'productFeedArticle'" class="btn-link">
                                            <i class="icon-plus"></i>
                                        </b-button>
                                    </div>

                                    <div class="accordion-card-header-td second">
                                        {{ $store.state.translations.translations['Product'] }}
                                    </div>
                                </div>

                                <b-collapse id="productFeedArticle" accordion="product-page" v-model="productPageAccordion['article']">
                                    <div class="collapse-body">
                                        <div class="card-body" v-if="showArticles && currentGroup">
                                            <ProductFeedArticle v-if="showArticles && currentGroup"
                                                                :article-group-id="currentGroup"
                                                                :key="currentGroup"
                                                                :params="params"
                                                                :show-title="false"
                                            />
                                        </div>

                                        <div class="card-body" v-else>
                                            <template v-if="params.articleSearchPerSubGroup">
                                                {{ $store.state.translations.translations['Select an article sub-group.'] }}
                                            </template>

                                            <template v-else>
                                                {{ $store.state.translations.translations['Select an article group.'] }}
                                            </template>
                                        </div>
                                    </div>
                                </b-collapse>
                            </div>
                        </div>
                    </template>

                    <ProductFeedArticle v-if="params && !params.articleSearchPerGroup"
                                        :showTitle="false"
                                        :params="params"
                    />
                </div>

                <ProductOverview :params="params" ref="productOverview"/>

                <div class="container">
                    <div class="col-12 d-flex flex-column flex-md-row align-items-center justify-content-center pt-3 pt-md-4 mb-5">
                        <button type="button" class="btn md btn-secondary m-3" @click="previous()">
                            {{ $store.state.translations.translations['Back'] }}
                        </button>

                        <button type="button" class="btn md btn-primary m-1 m-md-3" @click="next()" v-if="$store.state.products.products[orderId] && Object.keys($store.state.products.products[orderId]).length > 0">
                            {{ $store.state.translations.translations['Next'] }}
                        </button>
                    </div>
                </div>
            </form>
        </main>

        <SspFooter></SspFooter>
    </div>
</template>

<script>
    import ProductOverview from '@/components/_ProductOverview';
    import SspHeader from '@/components/_Header.vue';
    import SspFooter from '@/components/_Footer.vue';
    import InstructionsSection from '@/components/_InstructionsSection.vue';
    import ProductFeedArticleGroup from '@/components/_ProductFeedArticleGroup.vue';
    import ProductFeedArticle from '@/components/_ProductFeedArticle.vue';
    import {BButton, BCollapse, VBToggle} from 'bootstrap-vue-3';

    export default {
        components: {
            'ProductOverview': ProductOverview,
            'SspHeader': SspHeader,
            'SspFooter': SspFooter,
            'InstructionsSection': InstructionsSection,
            'ProductFeedArticleGroup': ProductFeedArticleGroup,
            'ProductFeedArticle': ProductFeedArticle,
            'b-button': BButton,
            'b-collapse': BCollapse,
        },
        directives: {
            'b-toggle': VBToggle,
        },
        data() {
            return {
                showArticles: false,
                selectedArticleGroup: null,
                selectedArticleSubGroup: null,
                currentGroup: null,
                articleGroups: null,
                articleSubGroups: null,
                merchantSetupsLoaded: false,
                merchantSetupId: this.$route.query.merchantSetupId,
                returnId: this.$route.query.returnId,
                orderId: this.$route.query.orderId,
                params: null,
                productPageAccordion: {
                    articleGroup: true,
                    articleSubGroup: false,
                    article: false,
                },
            }
        },
        created() {
            //load merchant setups (only)
            this.$store.dispatch('mcpModule/getAll').then(() => {
                this.merchantSetupsLoaded = true;
                this.params = this.$store.state.mcpModule.merchantSetups[this.$store.state.translations.currentLanguage][this.merchantSetupId].parameters;
            });

            this.$store.dispatch('getHasAdditionalInfoStep', this.returnId);
            this.$store.commit('OC/setLoaded', false);
        },
        methods: {
            // TODO: Mogelijk verplaatsen naar _ProductFeedArticleGroup.vue
            selectArticleGroup: function (articleGroup) {
                if (!articleGroup.ParentGroupId) {
                    this.selectedArticleGroup = articleGroup.Name;
                    this.selectedArticleSubGroup = null;
                } else {
                    this.selectedArticleSubGroup = articleGroup.Name;
                }

                this.currentGroup = articleGroup.ArticleGroupId;
                this.showArticles = false;

                if (this.params && this.params.articleSearchPerSubGroup && !articleGroup.ParentGroupId) {
                    this.articleSubGroups = null;

                    let url = new URL(this.$store.state.portalUrl + 'article/getGroups');
                    url.searchParams.set('parentGroupId', articleGroup.ArticleGroupId);
                    url.searchParams.set('merchantSetupId', this.merchantSetupId);

                    this.$http.get(url.href).then((data) => {
                        this.productPageAccordion['articleGroup'] = false;
                        this.articleSubGroups = data.data.data.articleGroups;

                        if (!this.articleSubGroups || this.articleSubGroups.length <= 0) {
                            this.showArticles = true;
                            this.productPageAccordion['article'] = true;
                        } else {
                            this.productPageAccordion['articleSubGroup'] = true;
                        }
                    });
                } else {
                    this.showArticles = true;
                    this.productPageAccordion['article'] = true;
                }
            },
            next: function () {
                var nextUrl = null;
                if (this.$store.state.hasAdditionalInfoStep?.[this.returnId] === true)
                    nextUrl = 'additionalInfo';
                else
                    nextUrl = 'checkout';
                this.$router.push({
                    path: nextUrl,
                    query: {returnId: this.returnId, orderId: this.orderId, merchantSetupId: this.merchantSetupId}
                });
            },
            previous: function () {
                if (this.params.skipOrderPage == true) {
                    this.$router.push({path: 'merchantSetupSelection'});
                } else {
                    this.$router.push({
                        path: 'order',
                        query: {returnId: this.returnId, merchantSetupId: this.merchantSetupId}
                    });
                }
            }
        }
    }
</script>

<style>
    .model-item {
        border: 1px solid #EFEFEF;
    }
</style>
