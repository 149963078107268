const translationsStore = {
    namespaced: true,
    state: {
        currentLanguage: null,
        translations: null,
        attributeLabels: null,
        placeHolders: null
    },
    mutations: {
        set(state, translations) {
            state.translations = translations;
        },
        setAttributeLabels(state, attributeLabels) {
            state.attributeLabels = attributeLabels;
        },
        setPlaceHolders(state, placeHolders) {
            state.placeHolders = placeHolders;
        },
        setCurrentLanguage(state, lang) {
            state.currentLanguage = lang;
        },
        clear(state) {
            state.currentLanguage = null;
            state.translations = null;
            state.attributeLabels = null;
            state.placeHolders = null;
        }
    },
    getters: {
        translation: (state) => (key) => {
            if (typeof state.translations !== 'undefined' && typeof state.translations[key] !== 'undefined') {
                return state.translations[key];
            }

            return key;
        }
    },
    actions: {

    }
};

export default translationsStore;
