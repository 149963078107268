var mixin = {
    methods: {
        mcpnext(returnObject, isExternalLoginRedirect = false) {
            let orderId = typeof returnObject.OrderId !== 'undefined' ? returnObject.OrderId : false;
            let mcp = this.$store.state.mcpModule.merchantSetups[this.$store.state.translations.currentLanguage][returnObject.MerchantSetupId];

            let skipOrderPage = orderId ? mcp.parameters.skipOrderPage : false;
            let currencySelectionRequired = !returnObject.CurrencyCode && Object.keys(mcp.currencies).length > 1;
            let hasConnector = mcp.hasConnector;

            if (hasConnector) {
                /* OrderConnector product page */
                if (isExternalLoginRedirect && orderId) {
                    this.$router.push({
                        name: 'OrderConnectorProductPage',
                        query: {returnId: returnObject.ReturnId, merchantSetupId: returnObject.MerchantSetupId, orderId: orderId}
                    });
                } else {
                    this.$router.push({
                        name: 'OrderConnectorPage',
                        query: {returnId: returnObject.ReturnId, merchantSetupId: returnObject.MerchantSetupId}
                    });
                }
            } else if (skipOrderPage && !currencySelectionRequired) {
                /* Products page */
                this.$router.push({
                    name: 'ProductPage',
                    query: {returnId: returnObject.ReturnId, merchantSetupId: returnObject.MerchantSetupId, orderId: orderId}
                });
            } else {
                /* Order info page */
                if (skipOrderPage) {
                    /* Order is known, only currency selection is required */
                    this.$router.push({
                        name: 'OrderPage',
                        query: {returnId: returnObject.ReturnId, merchantSetupId: returnObject.MerchantSetupId, orderId: orderId, selectCurrency: currencySelectionRequired}
                    });
                } else {
                    /* Order(s) have to be created */
                    this.$router.push({
                        name: 'OrderPage',
                        query: {returnId: returnObject.ReturnId, merchantSetupId: returnObject.MerchantSetupId, selectCurrency: currencySelectionRequired}
                    });
                }
            }
        }
    }
}

export default mixin;
