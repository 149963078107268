<template>
    <div class="page">
        <SspHeader activeMenuItem="track"/>

        <main class="main mb-5">
            <section class="request-shipment-section">
                <div class="container sm">
                    <h1>
                        {{ $store.state.translations.translations['Track your returns'] }}
                    </h1>
                </div>
            </section>

            <div class="container sm">
                <div class="d-flex justify-content-between">
                    <span class="label-title">
                        {{ $store.state.translations.translations['Return details'] }}
                    </span>

                    <div class="my-0 justify-content-end" style="margin-left: auto; margin-right: 20px; cursor: pointer" @click="backToOverview" v-if="$route.query.overview">
                        {{ $store.state.translations.translations['Back to overview'] }}
                    </div>
                </div>

                <div class="row my-3">
                    <div class="col-12">
                        <div class="accordion height-100">
                            <div class="accordion-card py-0">
                                <div class="card-body">
                                    <div v-if="isLoadingReturnModel">
                                        {{ $store.state.translations.translations['Loading return details...'] }}
                                    </div>

                                    <div v-else-if="isEmpty(returnModel)">
                                        {{ $store.state.translations.translations['Unable to retrieve return details.'] }}
                                    </div>

                                    <div class="row" v-else>
                                        <div class="col-6 col-sm-3">
                                            <strong>
                                                {{ $store.state.translations.attributeLabels['RTN_RTN_REFERENCE'] }}
                                            </strong>

                                            <p class="mb-0">
                                                {{ returnModel.ReturnReference }}
                                            </p>
                                        </div>

                                        <div class="col-6 col-sm-3">
                                            <strong>
                                                {{ $store.state.translations.attributeLabels['RTN_MERCHANT_SETUP_ID'] }}
                                            </strong>

                                            <p class="mb-0">
                                                {{ returnModel.MerchantSetupLabel }}
                                            </p>
                                        </div>

                                        <div class="col-6 col-sm-3 mt-3 mt-sm-0">
                                            <strong>
                                                {{ $store.state.translations.attributeLabels['RTN_RTN_CREATION'] }}
                                            </strong>

                                            <p class="mb-0">
                                                {{ returnModel.CreatedAt.substring(0, 10) }}
                                            </p>
                                        </div>

                                        <div class="col-6 col-sm-3 mt-3 mt-sm-0" v-if="typeof returnModel.User !== 'undefined'">
                                            <strong>
                                                {{ $store.state.translations.translations['Created by'] }}
                                            </strong>

                                            <p class="mb-0">
                                                {{ returnModel.User.RTN_USR_FIRSTNAME }} {{ returnModel.User.RTN_USR_LASTNAME }}
                                            </p>
                                        </div>

                                        <div class="col-6 col-sm-3 mt-3 mt-sm-0">
                                            <strong>
                                                {{ $store.state.translations.attributeLabels['RTN_STATUS'] }}
                                            </strong>

                                            <p class="mb-0 text-success">
                                                {{ returnModel.Status }}
                                            </p>
                                        </div>

                                        <template v-if="getCustomAttributesDisplayValues() && Object.keys(getCustomAttributesDisplayValues()).length > 0">
                                            <div class="row" v-if="isShowingMoreReturnDetails">
                                                <div class="col-6 col-sm-3 mt-3 mt-sm-0" v-for="(ca, key) in getCustomAttributesDisplayValues()" :key="key">
                                                    <strong>
                                                        {{ key }}
                                                    </strong>

                                                    <p class="mb-0" v-html="ca"></p>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="py-2">
                                                        <a href="#" class="back-link mb-0 pb-0"  @click.prevent="toggleReturnDetails()">
                                                            <template v-if="isShowingMoreReturnDetails">
                                                                {{ $store.state.translations.translations['Show less'] }}

                                                                <span class="icon icon-chevron-up ps-1"></span>
                                                            </template>

                                                            <template v-else>
                                                                {{ $store.state.translations.translations['Show more'] }}

                                                                <span class="icon icon-chevron-down ps-1"></span>
                                                            </template>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="label-title">
                    {{ $store.state.translations.translations['Products'] }}
                </div>

                <div class="row my-3">
                    <div class="col-12">
                        <div class="accordion height-100">
                            <div class="accordion-card py-0">
                                <div class="card-body">
                                    <template v-if="isLoadingRequestedProducts">
                                        {{ $store.state.translations.translations['Retrieving requested products...'] }}
                                    </template>

                                    <template v-else-if="isEmpty(requestedProducts)">
                                        {{ $store.state.translations.translations['Unable to retrieve requested products.'] }}
                                    </template>

                                    <template v-else>
                                        <div class="row">
                                            <div class="col-3 col-sm-3" v-if="showModelNumber !== false">
                                                <strong>
                                                    {{ capitalize($store.state.translations.attributeLabels['RTN_PDT_MODEL_NUMBER']) }}
                                                </strong>
                                            </div>

                                            <div class="col-5 col-sm-6">
                                                <strong>
                                                    {{ capitalize($store.state.translations.attributeLabels['RTN_PDT_DESCRIPTION']) }}
                                                </strong>
                                            </div>

                                            <div class="col-2 col-sm-3">
                                                <strong>
                                                    {{ capitalize($store.state.translations.attributeLabels['RTN_PDT_QUANTITY_REQUESTED']) }}
                                                </strong>
                                            </div>
                                        </div>

                                        <template v-for="(requestedProduct, index) in requestedProducts" :key="index">
                                            <div class="row mb-2" v-if="index < productLimit">
                                                <div class="col-3 col-sm-3" v-if="showModelNumber !== false">
                                                    {{ requestedProduct.ModelNumber }}
                                                </div>

                                                <div class="col-5 col-sm-6">
                                                    {{ requestedProduct.Description }}
                                                </div>

                                                <div class="col-2 col-sm-3">
                                                    {{ requestedProduct.Quantity }}
                                                </div>
                                            </div>
                                        </template>

                                        <template v-if="productCount > productLimit">
                                            <b-collapse id="show-more-products" v-model="isShowingMoreProducts">
                                                <template v-for="(requestedProduct, index) in requestedProducts" :key="index">
                                                    <div class="row mb-2" v-if="index >= productLimit">
                                                        <div class="col-3 col-sm-3">
                                                            {{ requestedProduct.ModelNumber }}
                                                        </div>

                                                        <div class="col-5 col-sm-6">
                                                            {{ requestedProduct.Description }}
                                                        </div>

                                                        <div class="col-2 col-sm-3">
                                                            {{ requestedProduct.Quantity }}
                                                        </div>
                                                    </div>
                                                </template>
                                            </b-collapse>

                                            <a v-b-toggle="'show-more-products'" class="back-link mb-0 pb-0" href="#" @click.prevent>
                                                <span class="when-open" v-if="isShowingMoreProducts">
                                                    {{ $store.state.translations.translations['Show less'] }}
                                                </span>

                                                <span class="when-closed" v-else>
                                                    {{ $store.state.translations.translations['Show more'] }}
                                                </span>
                                            </a>
                                        </template>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="label-title">
                    {{ $store.state.translations.translations['Messages'] }}
                </div>

                <div class="row my-3">
                    <div class="col-12">
                        <div class="accordion height-100">
                            <div class="accordion-card py-0">
                                <div class="card-body">
                                    <template v-if="isLoadingRequestedProducts">
                                        {{ $store.state.translations.translations['Retrieving messages'] + '...' }}
                                    </template>

                                    <template v-else>
                                        <template v-for="(note, key) in notes" :key="key">
                                            <div class="row mb-2" :style="note.messageModel.RTN_MSG_ID === replyId ? 'border: solid 1px blue;' : ''">
                                                <div class="col-12">
                                                    {{ note.messageModel.RTN_MSG_MESSAGE }}
                                                </div>

                                                <div class="col label--sm text-success">
                                                    {{ note.messageModel.RTN_MSG_SENDER_FIRSTNAME }} {{ note.messageModel.RTN_MSG_SENDER_LASTNAME }}
                                                </div>

                                                <div class="col label--sm text-success">
                                                    {{ note.messageModel.RTN_MSG_SENDER_EMAIL }}
                                                </div>

                                                <div class="col-6 label--sm text-success">
                                                    {{ note.messageModel.RTN_MSG_CREATION }}
                                                </div>

                                                <div class="col label--sm text-primary cursor-pointer" @click="reply(note.messageModel)">
                                                    {{  $store.state.translations.translations['Reply'] }}
                                                </div>

                                                <div class="col label--sm text-danger cursor-pointer" @click="deleteNote(note.messageModel.RTN_MSG_ID)">
                                                    <template v-if="user === note.messageModel.RTN_MSG_SENDER_EMAIL">
                                                        {{  $store.state.translations.translations['Delete'] }}
                                                    </template>
                                                </div>

                                                <div class="col-12">
                                                    <template v-if="note.messageModel.files">
                                                        <div class="d-flex overflow-auto">
                                                            <template v-if="Array.isArray(note.messageModel.files)">
                                                                <template v-for="document in note.messageModel.files" :key="document.fileModelId">
                                                                    <div class="me-5">
                                                                        <a :href="document.fileUrl" target="_blank">
                                                                            {{ document.fileName }}
                                                                        </a>

                                                                        <a href="#" class="text-danger ms-2" @click.prevent="deleteNoteFile(document.fileModelId)">
                                                                            {{ $store.state.translations.translations['Delete'] }}
                                                                        </a>
                                                                    </div>
                                                                </template>
                                                            </template>

                                                            <template v-else>
                                                                <div>
                                                                    <a :href="note.messageModel.files.fileUrl" target="_blank">
                                                                        {{ note.messageModel.files.fileName }}
                                                                    </a>

                                                                    <a href="#" class="text-danger ms-2" @click.prevent="deleteNoteFile(note.messageModel.files.fileModelId)">
                                                                        {{ $store.state.translations.translations['Delete'] }}
                                                                    </a>
                                                                </div>
                                                            </template>
                                                        </div>
                                                    </template>
                                                </div>
                                            </div>

                                            <template v-for="(subNote, key2) in note.submessageModels" :key="key + '_' + key2">
                                                <div class="row ms-3">
                                                    <div class="col-12">
                                                        {{ subNote.RTN_MSG_MESSAGE }}
                                                    </div>

                                                    <div class="col label--sm text-success">
                                                        {{ subNote.RTN_MSG_SENDER_FIRSTNAME }} {{ subNote.RTN_MSG_SENDER_LASTNAME }}
                                                    </div>

                                                    <div class="col label--sm text-success">
                                                        {{ subNote.RTN_MSG_SENDER_EMAIL }}
                                                    </div>

                                                    <div class="col-6 label--sm text-success">
                                                        {{ subNote.RTN_MSG_CREATION }}
                                                    </div>

                                                    <div class="col label--sm text-primary cursor-pointer">

                                                    </div>

                                                    <div class="col label--sm text-danger cursor-pointer" @click.prevent="deleteNote(subNote.RTN_MSG_ID)">
                                                        <template v-if="user === subNote.RTN_MSG_SENDER_EMAIL">
                                                            {{ $store.state.translations.translations['Delete'] }}
                                                        </template>
                                                    </div>

                                                    <div class="col-12">
                                                        <template v-if="subNote.files">
                                                            <div class="d-flex mt-4 overflow-auto">
                                                                <template v-if="Array.isArray(subNote.files)">
                                                                    <template v-for="document in subNote.files" :key="document.fileModelId">
                                                                        <div class="me-2">
                                                                            <a :href="document.fileUrl" target="_blank">
                                                                                {{ document.fileName }}
                                                                            </a>

                                                                            <a href="#" class="text-danger ms-2" @click.prevent="deleteNoteFile(document.fileModelId)">
                                                                                {{ $store.state.translations.translations['Delete'] }}
                                                                            </a>
                                                                        </div>
                                                                    </template>
                                                                </template>

                                                                <template v-else>
                                                                    <div>
                                                                        <a :href="subNote.files.fileUrl" target="_blank">
                                                                            {{ subNote.files.fileName }}
                                                                        </a>

                                                                        <a href="#" class="text-danger ms-2" @click.prevent="deleteNoteFile(subNote.files.fileModelId)">
                                                                            {{ $store.state.translations.translations['Delete'] }}
                                                                        </a>
                                                                    </div>
                                                                </template>
                                                            </div>
                                                        </template>
                                                    </div>
                                                </div>
                                            </template>
                                        </template>
                                    </template>

                                    <hr>

                                    <div class="row m-1" v-if="replyNote">
                                        <div class="col-12">
                                            <div class="row mb-2">
                                                <div class="col label--sm">
                                                    {{ $store.state.translations.translations['Replying on'] + ':' }}
                                                </div>

                                                <div class="col-12">
                                                    {{ replyNote.RTN_MSG_MESSAGE }}
                                                </div>

                                                <div class="col label--sm text-success">
                                                    {{ replyNote.RTN_MSG_SENDER_FIRSTNAME }} {{ replyNote.RTN_MSG_SENDER_LASTNAME }}
                                                </div>

                                                <div class="col label--sm text-success">
                                                    {{ replyNote.RTN_MSG_SENDER_EMAIL }}
                                                </div>

                                                <div class="col-6 label--sm text-success">
                                                    {{ replyNote.RTN_MSG_CREATION }}
                                                </div>
                                            </div>

                                            <textarea class="form-control mw-90"
                                                      rows="2"
                                                      v-model="newNote.RTN_MSG_MESSAGE"
                                                      :placeholder="$store.state.translations.translations['Add message']"
                                            >
                                            </textarea>

                                            <div class="mt-3">
                                                <label class="file">
                                                    <input type="file" multiple id="file" ref="file" @change="fileInputChange">
                                                </label>
                                            </div>

                                            <button type="button" class="btn md btn-primary mt-3" @click="saveNewNote()" v-if="!isSavingNote">
                                                {{ $store.state.translations.translations['Save'] }}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 col-md-6">
                        <div class="label-title">
                            {{ $store.state.translations.translations['Shipping'] }}
                        </div>

                        <div class="accordion my-3">
                            <div class="accordion-card py-0">
                                <div class="card-body">
                                    <template v-if="isLoadingCarrier">
                                        {{ $store.state.translations.translations['Loading instructions...'] }}
                                    </template>

                                    <template v-else-if="!foundCarrier">
                                        {{ $store.state.translations.translations['Unable to retrieve carrier instructions.'] }}
                                    </template>

                                    <template v-else>
                                        <div class="row mb-4">
                                            <div class="col-sm-8 col-12">
                                                <strong>
                                                    {{ $store.state.translations.translations['Carrier service'] }}
                                                </strong>

                                                <p>
                                                    {{ carrier[$store.state.translations.currentLanguage].Label }}
                                                </p>
                                            </div>

                                            <div class="col-sm-4 col-12">
                                                <a :href="trackingUrl" target="_blank" class="btn btn-primary my-2" v-if="trackingUrl">
                                                    <i class="icon-search-bold"></i>
                                                </a>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-sm-8 col-12">
                                                <strong>
                                                    {{ $store.state.translations.translations['Description'] }}
                                                </strong>

                                                <p>
                                                    {{ carrier[$store.state.translations.currentLanguage].Description }}
                                                </p>
                                            </div>

                                            <div class="col-sm-4 col-12">
                                                <template v-if="carrier[$store.state.translations.currentLanguage].Type.toLowerCase() === 'dropoff' && carrier[$store.state.translations.currentLanguage].DropoffInfoUrl">
                                                    <a :href="carrier[$store.state.translations.currentLanguage].DropoffInfoUrl" target="_blank" class="btn btn-primary my-2">
                                                        <i class="icon-map-marker"></i>
                                                    </a>
                                                </template>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>

                        <div class="label-title">
                            {{ $store.state.translations.translations['Documents'] }}
                        </div>

                        <div class="accordion my-3">
                            <div class="accordion-card py-0">
                                <div class="card-body">
                                    <template v-if="isLoadingDocuments">
                                        {{ $store.state.translations.translations['Retrieving documents...'] }}
                                    </template>

                                    <template v-else-if="!allowShippingLabel">
                                        {{ $store.state.translations.translations['The carrier will provide the shipping label.'] }}
                                    </template>

                                    <template v-else-if="!hasDocuments">
                                        {{ $store.state.translations.translations['No documents available.'] }}
                                    </template>

                                    <template v-else-if="!foundDocuments">
                                        {{ $store.state.translations.translations['Unable to retrieve shipment documents.'] }}
                                    </template>

                                    <template v-else>
                                        <div class="row mb-4" v-if="shippingLabelUrls">
                                            <div class="col-sm-8 col-12">
                                                <strong v-if="getPortalContent('ShippingLabelDocument').title">
                                                    {{ getPortalContent('ShippingLabelDocument').title }}
                                                </strong>

                                                <strong v-else>
                                                    {{ $store.state.translations.translations['Download return label'] }}
                                                </strong>

                                                <div v-html="getPortalContent('ShippingLabelDocument').content"
                                                     v-if="getPortalContent('ShippingLabelDocument')"></div>

                                                <p v-else>
                                                    {{ $store.state.translations.translations['Print the label and stick it to the top of the box.'] }}
                                                </p>
                                            </div>

                                            <div class="col-sm-4 col-12">
                                                <div v-for="(shippingLabelUrl, key) in shippingLabelUrls" :key="key">
                                                    <a :href="shippingLabelUrl" target="_blank" class="btn btn-primary my-2">
                                                        <i class="icon-download"></i>

                                                        <template v-if="shippingLabelUrls.length > 1">
                                                            {{ key + 1 }}
                                                        </template>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row mb-4" v-if="noPrintLabelUrls">
                                            <div class="col-sm-8 col-12">
                                                <strong v-if="getPortalContent('ShippingCodeDocument').title">
                                                    {{ getPortalContent('ShippingCodeDocument').title }}
                                                </strong>

                                                <strong v-else>
                                                    {{ $store.state.translations.translations['Download shipping code'] }}
                                                </strong>

                                                <div v-html="getPortalContent('ShippingCodeDocument').content"
                                                     v-if="getPortalContent('ShippingCodeDocument')"></div>

                                                <p v-else>
                                                    {{ $store.state.translations.translations['Show this code on your device for printing the label at the drop-off location.'] }}
                                                </p>
                                            </div>

                                            <div class="col-sm-4 col-12">
                                                <div v-for="(noPrintLabelUrl, key) in noPrintLabelUrls" :key="key">
                                                    <a :href="noPrintLabelUrl" target="_blank" class="btn btn-primary my-2">
                                                        <i class="icon-download"></i>

                                                        <template v-if="noPrintLabelUrls.length > 1">
                                                            {{ key + 1 }}
                                                        </template>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row mb-4" v-if="packingSlipUrl">
                                            <div class="col-sm-8 col-12">
                                                <strong v-if="getPortalContent('PackingSlipDocument')">
                                                    {{ getPortalContent('PackingSlipDocument').title }}
                                                </strong>

                                                <strong v-else>
                                                    {{ $store.state.translations.translations['Download packing slip'] }}
                                                </strong>

                                                <div v-html="getPortalContent('PackingSlipDocument').content"
                                                     v-if="getPortalContent('PackingSlipDocument')"></div>

                                                <p v-else>
                                                    {{ $store.state.translations.translations['Print the packing slip and put it in the box.'] }}
                                                </p>
                                            </div>

                                            <div class="col-sm-4 col-12">
                                                <a :href="packingSlipUrl" target="_blank" class="btn btn-primary my-2">
                                                    <i class="icon-download"></i>
                                                </a>
                                            </div>
                                        </div>

                                        <div class="row" v-if="shippingInvoiceUrl">
                                            <div class="col-sm-8 col-12">
                                                <strong v-if="getPortalContent('ShippingInvoiceDocument').title">
                                                    {{ getPortalContent('ShippingInvoiceDocument').title }}
                                                </strong>

                                                <strong v-else>
                                                    {{ $store.state.translations.translations['Download shipping invoice'] }}
                                                </strong>

                                                <div v-html="getPortalContent('ShippingInvoiceDocument').content"
                                                     v-if="getPortalContent('ShippingInvoiceDocument').content"></div>

                                                <p v-else>
                                                    {{ $store.state.translations.translations['Print 3 times. Put one copy in the box give 2 copies to the driver.'] }}
                                                </p>
                                            </div>

                                            <div class="col-sm-4 col-12">
                                                <a :href="shippingInvoiceUrl" target="_blank" class="btn btn-primary my-2">
                                                    <i class="icon-download"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-md-6">
                        <div class="label-title">
                            {{ $store.state.translations.translations['Communication'] }}
                        </div>

                        <div class="accordion my-3 height-100">
                            <div class="accordion-card height-100-calculated py-0">
                                <div class="card-body">
                                    <template v-if="isLoadingActiveShippingMails || isLoadingSentShippingMails">
                                        {{ $store.state.translations.translations['Loading communication history...'] }}
                                    </template>

                                    <template v-else-if="!activeEmailTemplates || (!isLoadingReturnModel && isEmpty(returnModel))">
                                        {{ $store.state.translations.translations['Unable to communication history.'] }}
                                    </template>

                                    <template v-else>
                                        <div class="row">
                                            <div class="col-7">
                                                <strong>
                                                    {{ $store.state.translations.translations['Email'] }}
                                                </strong>
                                            </div>

                                            <div class="col-5">
                                                <strong>
                                                    {{ $store.state.translations.translations['Date sent'] }}
                                                </strong>
                                            </div>
                                        </div>

                                        <div class="row mb-2" v-for="activeEmailTemplate in activeEmailTemplates" :key="activeEmailTemplate.id">
                                            <div class="col-7">
                                                {{ $store.state.translations.translations[activeEmailTemplate.name] }}
                                            </div>

                                            <div class="col-5" v-if="!isLoadingSentShippingMails && sentEmailTemplates && sentEmailTemplates[activeEmailTemplate.id]">
                                                {{ sentEmailTemplates[activeEmailTemplate.id] }}
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row" v-if="refundData">
                    <div class="col-12">
                        <div class="accordion height-100">
                            <div class="accordion-card height-100-calculated">
                                <div class="accordion-card-header">
                                    <h5 class="mx-3 my-0">
                                        {{ $store.state.translations.translations['Instant refund'] }}
                                    </h5>
                                </div>

                                <div class="collapse-body">
                                    <div class="card-body">
                                        <template v-if="isLoadingRefundData">
                                            <div class="row">
                                                <div class="col-12">
                                                    {{ $store.state.translations.translations['Loading payment status...'] }}
                                                </div>
                                            </div>
                                        </template>

                                        <template v-else>
                                            <div class="row">
                                                <div class="col-12">
                                                    <ReveniInstantRefund :refund-data="refundData"
                                                                         @instant-refund-finish="getRefundData()"
                                                    />
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>

        <SspFooter></SspFooter>
    </div>
</template>

<script>
    import SspHeader from '@/components/_Header.vue';
    import SspFooter from '@/components/_Footer.vue';
    import {BCollapse, VBToggle} from 'bootstrap-vue-3';
	import CustomAttributesDisplay from '@/components/_CustomAttributesDisplay.vue';
    import ReveniInstantRefund from '@/components/_ReveniInstantRefund.vue';
    import _ from 'lodash';

    export default {
        components: {
            'SspHeader': SspHeader,
            'SspFooter': SspFooter,
            'b-collapse': BCollapse,
            'ReveniInstantRefund': ReveniInstantRefund,
        },
        directives: {
            'b-toggle': VBToggle,
			'CustomAttributesDisplay': CustomAttributesDisplay,
        },
        data() {
            return {
                returnId: this.$route.query.returnId,
                returnIsArchived: false,
                translations: {
                    status: this.$store.state.translations.attributeLabels['RTN_RTN_CREATION'],
                    createdAt: this.$store.state.translations.attributeLabels['RTN_RTN_CREATION'],
                },

                notes: [],
                isSavingNote: false,
                replyId: false,
                replyNote: false,
                newNote: {
                    RTN_MSG_MESSAGE: '',
                },
                filesToUpload: null,
                user: false,

                isLoadingReturnModel: true,
                returnModel: null,

                isLoadingRequestedProducts: true,
                requestedProducts: null,

				isShowingMoreReturnDetails: false,

                isLoadingCarrier: true,
                foundCarrier: false,
                carrier: [],

                isLoadingTrackingUrl: true,
                trackingUrl: false,

                allowShippingLabel: false,
                hasDocuments: false,
                isLoadingDocuments: true,
                foundDocuments: false,
                documents: [],

                isLoadingActiveShippingMails: true,
                activeEmailTemplates: null,

                isLoadingSentShippingMails: true,
                sentEmailTemplates: null,

                productLimit: 3,
                productCount: 0,
                isShowingMoreProducts: false,

                isLoadingRefundData: false,
                isEligibleForRefund: false,
                refundData: null,
            };
        },
        created() {
            this.$store.dispatch('trackReturns/getById', [this.returnId, false]).then((data) => {
                this.returnModel = data;
                this.isLoadingReturnModel = false;
                this.returnIsArchived = this.returnModel.IsArchived;
				this.customAttributes = data.customAttributes;

                if (data.User) {
                    this.user = data.User.RTN_USR_EMAIL;
                }

                let productsPromise = this.$store.dispatch('products/getAll', this.returnModel.OrderId);
                let mcpModulePromise = this.$store.dispatch('mcpModule/getAll');

                Promise.all([productsPromise, mcpModulePromise]).then((data) => {
                    this.requestedProducts = _.values(data[0]);
                    this.isLoadingRequestedProducts = false;
                    this.productCount = this.requestedProducts.length;
                });

                this.getCarrier();

                this.$store.dispatch('shipmentModule/getTrackingUrl', this.returnId).then((data) => {
                    this.trackingUrl = data;
                    this.isLoadingTrackingUrl = false;
                });

                this.$store.dispatch('shipmentModule/getLabelUrls', this.returnId).then((data) => {
                    this.shippingLabelUrls = data.shippingLabelUrls;
                    this.noPrintLabelUrls = data.noPrintLabelUrls;
                    this.packingSlipUrl = data.packingSlipUrl;
                    this.shippingInvoiceUrl = data.shippingInvoiceUrl;

                    this.foundDocuments = this.shippingLabelUrls || this.packingSlipUrl || this.shippingInvoiceUrl || this.noPrintLabelUrls;
                    this.isLoadingDocuments = false;
                });

                this.$store.dispatch('shipmentModule/getActiveShippingMails', this.returnId).then((data) => {
                    this.activeEmailTemplates = data;
                    this.isLoadingActiveShippingMails = false;
                });

                this.$store.dispatch('shipmentModule/getSentShippingMails', this.returnId).then((data) => {
                    this.sentEmailTemplates = data;
                    this.isLoadingSentShippingMails = false;
                });


                this.getNotes();
            });

            this.getRefundData();
        },
        mounted() {
            global.context.authorization.$on('switchedLanguage', () => {
                this.getCarrier();
            });
        },
        unmounted() {
            global.context.authorization.$off('switchedLanguage');
        },
        methods: {
            capitalize(value) {
                if (!value) {
                    return '';
                }

                value = value.toString();
                return value.charAt(0).toUpperCase() + value.slice(1);
            },
            isEmpty(value) {
                return _.isEmpty(value);
            },
            getNotes() {
                const url = new URL(this.$store.state.portalUrl + 'message/getAll');
                url.searchParams.set('returnId', this.returnId);

                this.notes = [];

                this.$http.get(url).then((data)=> {
                    this.notes = data.data.data.notes;
                });
            },
            getPortalContent(section) {
                if (this.$store.state.portalContent.content && this.$store.state.portalContent.content[this.$store.state.translations.currentLanguage] && this.$store.state.portalContent.content[this.$store.state.translations.currentLanguage][section] && this.$store.state.portalContent.content[this.$store.state.translations.currentLanguage][section].RTN_PCT_ACTIVE != 0) {
                    if (!this.$store.state.portalContent.content[this.$store.state.translations.currentLanguage][section].RTN_PCT_TITLE &&
                        !this.$store.state.portalContent.content[this.$store.state.translations.currentLanguage][section].RTN_PCT_CONTENT) {
                        return false;
                    }

                    return {
                        'title': this.$store.state.portalContent.content[this.$store.state.translations.currentLanguage][section].RTN_PCT_TITLE,
                        'content': this.$store.state.portalContent.content[this.$store.state.translations.currentLanguage][section].RTN_PCT_CONTENT,
                    };
                }

                return false;
            },
            getCarrier() {
                this.isLoadingCarrier = true;

                this.$store.dispatch('carrierModule/getSelectedCarrier', this.returnId).then((data) => {
                    this.carrier[this.$store.state.translations.currentLanguage] = data;
                    this.foundCarrier = !!data;

                    if (this.foundCarrier) {
                        this.hasDocuments = this.carrier[this.$store.state.translations.currentLanguage]['HasDocuments'];
                        this.hasBooking = this.carrier[this.$store.state.translations.currentLanguage]['RequiresBooking'];
                        this.allowShippingLabel = this.carrier[this.$store.state.translations.currentLanguage]['AllowShippingLabel'];
                    }

                    this.isLoadingCarrier = false;
                });
            },
            backToOverview() {
                if (this.$route.query.overview === 'track') {
                    this.$router.push({
                        path: 'track'
                    });
                } else if (this.$route.query.overview === 'history') {
                    this.$router.push({
                        path: 'history'
                    });
                }
            },
			getCustomAttributesDisplayValues() {
				let customAttributeDisplayValues = {};

				if (this.customAttributes) {
					for (const [key, customAttribute] of Object.entries(this.customAttributes)) {
						customAttributeDisplayValues[key] = customAttribute;
					}
				}
				return customAttributeDisplayValues;
			},
            fileInputChange() {
                if(this.$refs && this.$refs.file && this.$refs.file.files)
                    this.filesToUpload = this.$refs.file.files;
                else
                    this.filesToUpload = undefined;
            },
			toggleReturnDetails() {
				this.isShowingMoreReturnDetails = !this.isShowingMoreReturnDetails;
			},
            saveNewNote() {
                let url = new URL(this.$store.state.portalUrl + 'message/save');
                url.searchParams.set('returnId', this.returnId);
                let params = new FormData();
                params.append('Message[RTN_MSG_MESSAGE]', this.newNote.RTN_MSG_MESSAGE);
                if (this.replyId) {
                    url.searchParams.set('replyId', this.replyId);
                }

                if(this.$refs && this.$refs.file && this.$refs.file.files) {
                    for (const file of this.$refs.file.files) {
                        params.append('Message[files][]', file);
                    }
                }
                this.$http.post(url, params).then(()=> {
                    this.replyNote = false;
                    this.replyId = false;
                    this.newNote = {
                        RTN_MSG_MESSAGE: '',
                    };
                    this.filesToUpload = null;
                    this.getNotes();
                });
            },
            deleteNoteFile(fileModelId) {
                const url = new URL(this.$store.state.portalUrl + 'message/deleteNoteFile');
                const params = new FormData();
                params.append('FileModel[RTN_WFM_ID]', fileModelId);

                this.$http.post(url.href, params).then(() => {
                    this.getNotes();
                });
            },
            deleteNote(id) {
                const url = new URL(this.$store.state.portalUrl + 'message/delete');
                url.searchParams.set('noteId', id);

                this.$http.post(url.href).then(() => {
                    this.getNotes(true);
                });
            },
            reply(noteModel) {
                //cancel existing active reply
                if (this.replyId === noteModel.RTN_MSG_ID) {
                    this.replyId = false;
                    this.replyNote = false;
                } else {
                    this.replyNote = noteModel;
                    this.replyId = noteModel.RTN_MSG_ID;
                }
            },
            getRefundData() {
                this.isLoadingRefundData = true;
                this.refundData = null;
                this.isEligibleForRefund = false;

                const url = new URL(this.$store.state.portalUrl + 'refund/getRefundData');
                url.searchParams.set('returnId', this.returnId);

                this.$http.get(url.href).then((data) => {
                    this.isLoadingRefundData = false;

                    if (data && data.data && data.data.data && data.data.data.refund) {
                        if (data.data.data.refund.status === 'incomplete') {
                            this.isEligibleForRefund = true;
                        }

                        this.refundData = data.data.data.refund;
                    }
                });
            }
        },
        computed: {
            showModelNumber() {
                return this.$store.state.mcpModule?.merchantSetups?.[this.$store.state.translations?.currentLanguage]?.[this.returnModel?.MerchantSetupId]?.parameters?.showModelNumber;
            }
        }
    }
</script>

<style scoped>
    .height-100 {
        height: 100%;
    }

    .height-100-calculated {
        height: calc(100% - 67px);
    }
</style>
