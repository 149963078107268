<template>
    <div class="page">
        <SspHeader activeMenuItem="create"/>

        <main class="main">
            <content-block section="HomePageTop" :key="$store.state.translations.currentLanguage" v-if="showContentBlockSection" />

            <ReturnOverview class="pt-5 pb-3"/>

            <div class="container sm policies-container my-5">
                <div class="row">
                    <div class="col-12">
                        <InputFieldError attribute="attributes" :errors="errors"/>
                    </div>

                    <div class="form-group w-100">
                        <label for="country-selection">
                            {{ $store.state.translations.translations['Let\'s get started with your return.'] }}
                        </label>

                        <model-list-select
                                id="country-selection"
                                :list="$store.state.countries.countries"
                                option-value="code"
                                option-text="label"
                                v-model="selectedCountry"
                                :placeholder="$store.state.translations.translations['Please select your country.']"
                                required
                        >
                        </model-list-select>

                        <div v-if="Object.keys(administrativeAreas).length > 0" class="my-3">
                            <label for="administrative-area" v-if="administrativeAreaLabel">
                                {{ $store.state.translations.translations['{administrativeArea} selection'].replace('{administrativeArea}', $store.state.translations.translations[administrativeAreaLabel]) }}
                            </label>

                            <model-list-select
                                    id="administrative-area"
                                    :list="administrativeAreas"
                                    option-value="code"
                                    option-text="label"
                                    v-model="selectedAdministrativeArea"
                                    :placeholder="$store.state.translations.translations['Select your {administrativeArea}'].replace('{administrativeArea}', $store.state.translations.translations[administrativeAreaLabel])"
                                    required
                            >
                            </model-list-select>
                        </div>
                    </div>
                </div>

                <template v-if="selectedCountry && (Object.keys(administrativeAreas).length === 0 || selectedAdministrativeArea)">
                    <span v-if="loadingMerchantSetups">
                        {{ $store.state.translations.translations['Loading return types...'] }}
                    </span>

                    <span v-else-if="creatingReturn">
                        {{ $store.state.translations.translations['Saving...'] }}
                    </span>

                    <template v-else-if="!isLoadingAdministrativeAreas && currentUser && currentUser.OrganizationId && organizationLocationsInCountry && organizationLocationsInCountry.length === 0 && !acknowledgedWarning">
                        <p>
                            {{ $store.state.translations.translations['No ship-from location has been created for the selected country. Please inform your contact person. You can continue creating a return.'] }}
                        </p>

                        <button @click.prevent="acknowledgedWarning = true" class="btn btn-primary">
                            {{ $store.state.translations.translations['Continue'] }}
                        </button>
                    </template>

                    <div v-else class="row justify-content-center">
                        <template v-if="$store.state.portal.parameters.enableMerchantSetupUseCase">
                            <template v-if="useCases && useCases.length">
                                <div class="form-group w-100">
                                    <label>
                                        {{ $store.state.translations.translations['How can we help you?'] }}
                                    </label>

                                    <MerchantSetupUseCaseDropdown :use-cases="useCases" class="w-100" @result="merchantSetupUseCaseResult" :disabled="isSendingUseCaseEmail" />

                                    <template v-if="selectedMerchantSetupUseCase && selectedMerchantSetupUseCase.type === 'url'">
                                        <template v-if="selectedMerchantSetupUseCase.translatedMessage">
                                            <p class="mt-3">
                                                {{ selectedMerchantSetupUseCase.translatedMessage }}
                                            </p>
                                        </template>

                                        <template v-else-if="selectedMerchantSetupUseCase.message">
                                            <p class="mt-3">
                                                {{ selectedMerchantSetupUseCase.message }}
                                            </p>
                                        </template>

                                        <a :href="createContactLink(selectedMerchantSetupUseCase.url)" target="_blank" class="btn btn-primary">
                                            {{ $store.state.translations.translations['Visit our website'] }}
                                        </a>
                                    </template>

                                    <template v-if="selectedMerchantSetupUseCase && selectedMerchantSetupUseCase.type === 'email'">
                                        <template v-if="hasSentUseCaseMail">
                                            <p class="mt-4">
                                                {{ $store.state.translations.translations['Your message has been sent!'] }}
                                            </p>
                                        </template>

                                        <template v-else>
                                            <p class="mt-4">
                                                {{ $store.state.translations.translations['Get help from our support team.'] }}
                                            </p>

                                            <textarea
                                                    class="form-control my-3"
                                                    rows="4"
                                                    v-model="useCaseEmailMessage"
                                                    :placeholder="$store.state.translations.translations['Your message']"
                                                    :disabled="isSendingUseCaseEmail"
                                            />

                                            <InputFieldError attribute="useCaseMessage" :errors="errors"/>

                                            <p>
                                                {{ $store.state.translations.translations['Please enter an email address that we can use to send a reply to.'] }}
                                            </p>

                                            <input class="form-control mb-4"
                                                   type="email"
                                                   v-model="useCaseSenderEmail"
                                                   :placeholder="$store.state.translations.translations['Your email address']"
                                                   :disabled="isSendingUseCaseEmail"
                                            >

                                            <InputFieldError :attribute="['useCase', 'RTN_UCE_SENDER_EMAIL']" :errors="errors"/>

                                            <button
                                                    class="btn btn-primary"
                                                    @click.prevent="sendUseCaseEmail()"
                                                    :disabled="isSendingUseCaseEmail"
                                            >
                                                {{ $store.state.translations.translations['Send message'] }}
                                            </button>
                                        </template>
                                    </template>
                                </div>
                            </template>

                            <template v-else>
                                {{ $store.state.translations.translations['Returns are not possible for the chosen country.'] }}
                            </template>
                        </template>

                        <template v-else>
                            <div class="col-md-6 col-lg-4 policy-block-wrapper" v-for="merchantSetup in merchantSetupsByCountry[$store.state.translations.currentLanguage]" :key="merchantSetup.MerchantSetupId">
                                <a href="#" class="policy-block" @click="save(merchantSetup.MerchantSetupId)">
                                    <span>{{ merchantSetup.Label }}</span>

                                    <img v-bind:src="merchantSetup.returnTypeImage" class="img-fluid" alt="">
                                </a>

                                <span class="merchant-setup-description" v-html="merchantSetup.Description"></span>
                            </div>
                        </template>
                    </div>
                </template>
            </div>
        </main>

        <SspFooter></SspFooter>
    </div>
</template>

<script>
    import SspHeader from '@/components/_Header.vue';
    import SspFooter from '@/components/_Footer.vue';
    import mcpnext from '../mixins/mcpnext.js';
    import ReturnOverview from '@/components/_DraftReturnOverview.vue';
    import {ModelListSelect} from 'vue-search-select';
    import ContentBlock from '@/components/_ContentBlock.vue';
    import InputFieldError from '@/components/_InputFieldError.vue';
    import MerchantSetupUseCaseDropdown from '@/components/_MerchantSetupUseCaseDropdown.vue';
    import _ from 'lodash';

    export default {
        mixins: [
            mcpnext
        ],
        components: {
            'SspHeader': SspHeader,
            'SspFooter': SspFooter,
            'ReturnOverview': ReturnOverview,
            'ModelListSelect': ModelListSelect,
            'ContentBlock': ContentBlock,
            'InputFieldError': InputFieldError,
            'MerchantSetupUseCaseDropdown': MerchantSetupUseCaseDropdown,
        },
        data() {
            return {
                creatingReturn: false,
                loadingMerchantSetups: false,
                merchantSetupsLoaded: false,
                pageDataLoaded: false,
                pageData: [],
                selectedCountry: false,
                selectedAdministrativeArea: false,
                administrativeAreaLabel: '',
                isLoadingAdministrativeAreas: false,
                administrativeAreas: [],
                merchantSetupsByCountry: [],
                saveDraftAfterDelete: false,
                currentUser: null,
                organizationLocations: null,
                acknowledgedWarning: false,
                errors: {},
                selectedMerchantSetupUseCase: null,
                merchantSetupUseCases: [],
                useCaseEmailMessage: null,
                useCaseSenderEmail: null,
                isSendingUseCaseEmail: false,
                hasSentUseCaseMail: false,
            }
        },
        created() {
            this.merchantSetupsLoaded = false;

            this.$store.dispatch('mcpModule/getAll').then(() => {
                this.merchantSetupsLoaded = true;

                if (this.$store.state.countries.countries.length === 1) {
                    this.selectedCountry = this.$store.state.countries.countries[0].code;

                    // Attempt to auto-select a use case if there is no language switch necessary. Otherwise the auto-select will be triggered after the language switch.
                    if (navigator.language.slice(0, 2) === this.$store.state.translations.currentLanguage) {
                        this.autoSelectUseCase();
                    }
                }
            });

            this.$store.dispatch('getCurrentUser').then((data) => {
                this.currentUser = data;
            });

            this.getMerchantSetupUseCases();
            this.getOrganizationLocations();
        },
        mounted() {
            global.context.authorization.$on('switchedLanguage', () => {
                this.merchantSetupsLoaded = false;

                this.$store.dispatch('mcpModule/getAll').then(() => {
                    this.merchantSetupsLoaded = true;
                    this.setMerchantSetups();
                    this.autoSelectUseCase();
                });

                this.getMerchantSetupUseCases();
            });
        },
        methods: {
            setCountry() {
                this.selectedAdministrativeArea = null;
                this.administrativeAreaLabel = '';
                this.isLoadingAdministrativeAreas = true;

                let administrativeAreasUrl = new URL(this.$store.state.portalUrl + 'return/getAdministrativeAreas');
                administrativeAreasUrl.searchParams.set('countryCode', this.selectedCountry);

                this.$http.get(administrativeAreasUrl.href).then((data) => {
                    this.isLoadingAdministrativeAreas = false;
                    this.administrativeAreas = [];

                    if (data.data.data.administrativeAreas) {
                        this.administrativeAreas = _.toPairs(data.data.data.administrativeAreas).map(a => {
                            return {code: a[0], label: a[1]};
                        });
                    }

                    if (Object.keys(this.administrativeAreas).length === 0) {
                        this.setMerchantSetups();
                    } else {
                        let administrativeAreaLabelUrl = new URL(this.$store.state.portalUrl + 'return/getAdministrativeAreaLabel');
                        administrativeAreaLabelUrl.searchParams.set('countryCode', this.selectedCountry);

                        this.$http.get(administrativeAreaLabelUrl.href).then((data) => {
                            this.administrativeAreaLabel = data.data.data.administrativeAreaLabel;
                        });
                    }
                });
            },
            setMerchantSetups() {
                this.merchantSetupsByCountry = {};
                this.merchantSetupsByCountry[this.$store.state.translations.currentLanguage] = {};

                var i = 0;
                for (var mcp in this.$store.state.mcpModule.merchantSetups[this.$store.state.translations.currentLanguage]) {
                    if (this.$store.state.mcpModule.merchantSetups[this.$store.state.translations.currentLanguage][mcp].Visible != true) {
                        continue;
                    }

                    i++;
                    if (this.selectedCountry in this.$store.state.mcpModule.merchantSetups[this.$store.state.translations.currentLanguage][mcp].countries) {
                        let merchantSetup = this.$store.state.mcpModule.merchantSetups[this.$store.state.translations.currentLanguage][mcp];
                        let order = typeof merchantSetup.OrderNumber !== 'undefined' && merchantSetup.OrderNumber ? parseInt(merchantSetup.OrderNumber) : i;
                        this.merchantSetupsByCountry[this.$store.state.translations.currentLanguage][order] = this.$store.state.mcpModule.merchantSetups[this.$store.state.translations.currentLanguage][mcp];
                    }
                }

                // Auto-select a merchant setup if there is only 1 merchant setup available, and the user does not have any open draft returns, and use cases are not enabled.
                if (!this.creatingReturn && !this.$store.state.portal.parameters.enableMerchantSetupUseCase && (!this.$store.state.draftReturns.returns || Object.keys(this.$store.state.draftReturns.returns).length === 0) && Object.keys(this.merchantSetupsByCountry[this.$store.state.translations.currentLanguage]).length === 1) {
                    const key = Object.keys(this.merchantSetupsByCountry[this.$store.state.translations.currentLanguage])[0];
                    this.save(this.merchantSetupsByCountry[this.$store.state.translations.currentLanguage][key].MerchantSetupId);
                }
            },
            save(merchantSetupId) {
                let skipOrderPage = this.$store.state.mcpModule.merchantSetups[this.$store.state.translations.currentLanguage][merchantSetupId].parameters.skipOrderPage;
                this.creatingReturn = true;
                let url = new URL(this.$store.state.portalUrl + 'return/save');
                url.searchParams.set('merchantSetupId', merchantSetupId);
                url.searchParams.set('country', this.selectedCountry);

                if(this.$route.query.appId)
                    url.searchParams.set('appId', this.$route.query.appId);

                if(this.$route.query.ticket)
                    url.searchParams.set('ticket', this.$route.query.ticket);

                if (this.selectedAdministrativeArea) {
                    url.searchParams.set('administrativeArea', this.selectedAdministrativeArea);
                }

                if (skipOrderPage)
                    url.searchParams.set('skipOrder', 'true');

                this.$http.post(url.href).then((data) => {
                    if (data.data.data.errors) {
                        this.errors = data.data.data.errors;
                        this.creatingReturn = false;
                    }

                    this.saveDraftAfterDelete = data.data.data.returnModel;
                    this.mcpnext(data.data.data.returnModel);
                });
            },
            getOrganizationLocations() {
                let url = new URL(this.$store.state.portalUrl + 'user/getOrganizationLocations');

                this.$http.get(url.href).then((data) => {
                    if (data.data.data.organizationLocations) {
                        this.organizationLocations = data.data.data.organizationLocations;
                    }
                });
            },
            getUseCaseArray(array, parentId) {
                if (this.merchantSetupUseCases) {
                    let useCases = this.merchantSetupUseCases.filter((useCase) => {
                        return useCase.RTN_MUC_PARENT_ID == parentId;
                    });

                    if (useCases) {
                        for (const useCase of useCases) {
                            let subUseCases = [];
                            this.getUseCaseArray(subUseCases, useCase.RTN_MUC_ID);

                            useCase.subUseCases = subUseCases;
                            array.push(useCase);
                        }
                    }
                }

                return [];
            },
            merchantSetupUseCaseResult(result) {
                if (result) {
                    this.selectedMerchantSetupUseCase = result;

                    if (result.type === 'merchant-setup' && result.merchantSetupId) {
                        this.save(result.merchantSetupId);
                    }
                }
            },
            autoSelectUseCase() {
                // Auto-select a use case if there is only 1 use case available, and the user does not have any open draft returns.
                if (!this.creatingReturn && this.$store.state.portal.parameters.enableMerchantSetupUseCase && (!this.$store.state.draftReturns.returns || Object.keys(this.$store.state.draftReturns.returns).length === 0) && this.useCases.length === 1) {
                    const selectedUseCase = this.useCases[0];

                    this.merchantSetupUseCaseResult({
                        type: selectedUseCase.RTN_MUC_RESULT_TYPE,
                        merchantSetupId: selectedUseCase.RTN_MCP_ID,
                        url: selectedUseCase.RTN_MUC_URL,
                        id: selectedUseCase.RTN_MUC_ID,
                        message: selectedUseCase.RTN_MUC_MESSAGE,
                        translatedMessage: selectedUseCase.translatedMessage
                    });
                }
            },
            getMerchantSetupUseCases() {
                this.merchantSetupUseCases = [];

                const url = new URL(this.$store.state.portalUrl + 'app/getMerchantSetupUseCases');
                url.searchParams.set('languageCode', this.$store.state.translations.currentLanguage);

                this.$http.get(url.href).then((data) => {
                    if (data && data.data && data.data.data && data.data.data.useCases) {
                        this.merchantSetupUseCases = data.data.data.useCases;

                        if (this.merchantSetupsLoaded) {
                            this.autoSelectUseCase();
                        }
                    }
                });
            },
            createContactLink(url) {
                if (url.indexOf('http') === -1) {
                    url = 'https://' + url;
                }

                return url;
            },
            validateUseCaseEmailForm() {
                this.errors = {};

                if (!this.useCaseSenderEmail) {
                    this.errors['RTN_UCE_SENDER_EMAIL'] = this.$store.state.translations.translations['This field is required.'];
                }

                if (!this.useCaseEmailMessage) {
                    this.errors['useCaseMessage'] = this.$store.state.translations.translations['This field is required.'];
                }

                return Object.keys(this.errors).length === 0;
            },
            sendUseCaseEmail() {
                if (!this.validateUseCaseEmailForm()) {
                    return false;
                }

                this.errors = {};
                this.isSendingUseCaseEmail = true;

                const url = new URL(this.$store.state.portalUrl + 'app/sendUseCaseEmail');
                const params = new URLSearchParams();
                params.append('UseCase[ID]', this.selectedMerchantSetupUseCase.id);
                params.append('UseCase[MESSAGE]', this.useCaseEmailMessage);
                params.append('UseCase[SENDER_EMAIL]', this.useCaseSenderEmail);

                this.$http.post(url.href, params).then((data) => {
                    this.isSendingUseCaseEmail = false;

                    if (data && data.data && data.data.data && data.data.data.errors) {
                        this.errors = data.data.data.errors;
                    } else if (data && data.data && data.data.data && data.data.data.success && data.data.data.success === true) {
                        this.hasSentUseCaseMail = true;
                    }
                });
            }
        },
        unmounted() {
            //we willen pas de retour toevoegen nadat het scherm verdwenen is anders krijg je flikkering 0.1 sec voordat je verspringt
            if (this.saveDraftAfterDelete) {
                this.$store.commit('draftReturns/add', this.saveDraftAfterDelete);
                this.saveDraftAfterDelete = false;
            }

            global.context.authorization.$off('switchedLanguage');
        },
        watch: {
            selectedCountry(value) {
                this.acknowledgedWarning = false;

                if (value === null || value === '') {
                    this.selectedAdministrativeArea = null;
                    this.administrativeAreas = [];
                    return;
                }

                this.setCountry();
            },
            selectedAdministrativeArea(value) {
                if (value === null) {
                    return;
                }

                this.setMerchantSetups();
            }
        },
        computed: {
            organizationLocationsInCountry() {
                return this.organizationLocations && this.organizationLocations.filter((organizationLocation) => {
                    return this.selectedCountry && organizationLocation.Country && organizationLocation.Country === this.selectedCountry;
                });
            },
            primaryMerchantSetupUseCases() {
                if (this.merchantSetupUseCases) {
                    return this.merchantSetupUseCases.filter((useCase) => {
                        return useCase.RTN_MUC_PARENT_ID == null;
                    });
                }

                return [];
            },
            useCases() {
                if (this.primaryMerchantSetupUseCases) {
                    let result = [];

                    for (const primaryUseCase of this.primaryMerchantSetupUseCases) {
                        let subUseCases = [];
                        this.getUseCaseArray(subUseCases, primaryUseCase.RTN_MUC_ID);

                        primaryUseCase.subUseCases = subUseCases;
                        result.push(primaryUseCase);
                    }

                    return result;
                }

                return [];
            },
            showContentBlockSection() {
                const contents = this.$store.state.portalContent?.content?.[this.$store.state.translations?.currentLanguage]?.['HomePageTop'];
                const isActive = contents?.RTN_PCT_ACTIVE != 0;
                const hasTitle = !!contents?.RTN_PCT_TITLE;
                const hasSubtitle = !!contents?.RTN_PCT_SUB_TITLE;
                const hasContent = !!contents?.RTN_PCT_CONTENT;

                return isActive && (hasTitle || hasSubtitle || hasContent);
            }
        }
    }
</script>

<style scoped>
    .merchant-setup-description {
        text-align: justify;
    }

    .policy-block {
        margin: 40px auto 20px;
    }
</style>
