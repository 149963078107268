<template>
    <section v-if="returnsLoaded && !isEmpty($store.state.draftReturns.returns)">
        <div class="container sm">
            <label class="mb-3 pl-1">
                {{ $store.state.translations.translations['Continue with your open return'] }}
            </label>

            <div class="custom-table">
                <div class="custom-table-head">
                    <div class="custom-table-head-cell four">
                        {{ $store.state.translations.attributeLabels['RTN_RTN_REFERENCE'] }}
                    </div>

                    <div class="custom-table-head-cell two">
                        {{ $store.state.translations.attributeLabels['RTN_MERCHANT_SETUP_ID'] }}
                    </div>

                    <div class="custom-table-head-cell three">
                        {{ $store.state.translations.attributeLabels['RTN_RTN_CREATION'] }}
                    </div>

                    <div class="custom-table-head-cell one">
                        {{ $store.state.translations.attributeLabels['RTN_STATUS'] }}
                    </div>

                    <div class="custom-table-head-cell five">

                    </div>
                </div>

                <div class="custom-table-body">
                    <div class="custom-table-body-row" v-for="rtn in $store.state.draftReturns.returns" :key="rtn.ReturnId">
                        <template v-if="returnsBeingDeleted.indexOf(rtn.ReturnId) != -1">
                            <div class="custom-table-td">
                                {{ translations.deletingreturn }}
                            </div>
                        </template>

                        <template v-else>
                            <div class="custom-table-td four">
                                {{ rtn.ReturnReference }}
                            </div>

                            <div class="custom-table-td two">
                                {{ rtn.MerchantSetupLabel }}
                            </div>

                            <div class="custom-table-th-mobile">
                                {{ translations.creationdate }}
                            </div>

                            <div class="custom-table-th-mobile">
                                {{ translations.status }}
                            </div>

                            <div class="custom-table-td three">
                                {{ rtn.CreatedAt }}
                            </div>

                            <div class="custom-table-td one">
                                <span class="text-success">{{ rtn.Status}}</span>
                            </div>

                            <div class="custom-table-td five">
                                <button type="button" name="button" class="btn btn-primary" v-on:click="mcpnext(rtn)">
                                    {{ $store.state.translations.translations['Continue'] }}
                                </button>

                                <button type="button" name="button" class="btn btn-primary deleteBtn"
                                        v-on:click="deleteReturn(rtn.ReturnId)">X
                                </button>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import mcpnext from '../mixins/mcpnext.js';
    import _ from 'lodash';

    export default {
        mixins: [
            mcpnext
        ],
        data() {
            return {
                returns: null,
                returnsLoaded: false,
                returnsBeingDeleted: [],
                translations: {
                    deletingreturn: this.$store.state.translations.translations['Deleting return...'],
                    creationdate: this.$store.state.translations.attributeLabels['RTN_RTN_CREATION'],
                    status: this.$store.state.translations.attributeLabels['RTN_STATUS'],
                }
            }
        },
        created() {
            this.$store.dispatch('draftReturns/getAll').then(() => {
                this.returnsLoaded = true;
            });


        },
        methods: {
            deleteReturn(returnId) {
                this.returnsBeingDeleted.push(returnId);
                let url = new URL(this.$store.state.portalUrl + 'return/delete');
                url.searchParams.set('returnId', returnId);
                this.$http.post(url.href).then(() => {
                    this.$store.commit('draftReturns/delete', returnId);
                });
            },
            isEmpty(value) {
                return _.isEmpty(value);
            }
        }
    }
</script>

<style>
    .deleteBtn {
        margin-left: 10px;
    }
</style>
