<template>
    <div class="page">
        <SspHeader/>

        <main class="main">
            <section class="middle-section">
                <div class="container text-center">
                    <h1 v-if="getErrorPageContent().title">
                        {{ getErrorPageContent().title }}
                    </h1>

                    <h1 v-else>
                        {{ $store.state.translations.translations['An error has occurred'] }}
                    </h1>

                    <h2 v-if="getErrorPageContent().subtitle">
                        {{ getErrorPageContent().subtitle }}
                    </h2>

                    <div v-html="getErrorPageContent().content" v-if="getErrorPageContent().content"></div>

                    <div v-else>
                        {{ $store.state.translations.translations['Click the button below to return to the previous page and try again, or contact us.'] }}
                    </div>

                    <button @click="$router.go(-1)" class="btn btn-primary md mt-5">
                        {{ $store.state.translations.translations['Back'] }}
                    </button>
                </div>
            </section>
        </main>

        <SspFooter></SspFooter>
    </div>
</template>

<script>
    import SspHeader from '@/components/_Header.vue';
    import SspFooter from '@/components/_Footer.vue';

    export default {
        name: 'ErrorPage',
        components: {
            'SspHeader': SspHeader,
            'SspFooter': SspFooter,
        },
        methods: {
            getErrorPageContent() {
                if (this.$store.state.portalContent?.content?.[this.$store.state.translations?.currentLanguage]?.['ErrorPage']) {
                    return {
                        'title': this.$store.state.portalContent.content[this.$store.state.translations.currentLanguage]['ErrorPage'].RTN_PCT_TITLE,
                        'subtitle': this.$store.state.portalContent.content[this.$store.state.translations.currentLanguage]['ErrorPage'].RTN_PCT_SUB_TITLE,
                        'content': this.$store.state.portalContent.content[this.$store.state.translations.currentLanguage]['ErrorPage'].RTN_PCT_CONTENT,
                    };
                }

                return false;
            },
        }
    }
</script>

<style scoped>

</style>
