<template>
    <div class="page">
        <SspHeader activeMenuItem="ship"/>

        <main class="main">
            <InstructionsSection contentBlockSection="ShipInstructionsPage" progressBarCurrentModule="ship" progressBarCurrentStep="packAndShip"/>

            <section>
                <div class="container sm pb-4">
                    <template v-if="carrier[$store.state.translations.currentLanguage] && (!carrier[$store.state.translations.currentLanguage].RequiresBooking || (carrier[$store.state.translations.currentLanguage].RequiresBooking && hasSavedPreferredPickupDate)) && !isEligibleForRefund">
                        <span class="label-title">
                            {{ $store.state.translations.translations['Your return label'] }}
                        </span>

                        <div class="row my-3">
                            <div class="col-12">
                                <div class="accordion height-100">
                                    <div class="accordion-card py-0 height-100-calculated">
                                        <div class="card-body">
                                            <template v-if="returnIsArchived">
                                                <div class="row">
                                                    <div class="col-12">
                                                        {{ $store.state.translations.translations['The details for this return are not available anymore.'] }}
                                                    </div>
                                                </div>
                                            </template>

                                            <template v-else-if="isLoadingCarrier">
                                                <div class="row">
                                                    <div class="col-12">
                                                        {{ $store.state.translations.translations['Loading instructions...'] }}
                                                    </div>
                                                </div>
                                            </template>

                                            <template v-else>
                                                <div class="row">
                                                    <div class="col-12 col-md-6">
                                                        <div v-if="returnIsArchived">
                                                            {{ $store.state.translations.translations['The details for this return are not available anymore.'] }}
                                                        </div>

                                                        <template v-else-if="isLoadingCarrier">
                                                            {{ $store.state.translations.translations['Loading instructions...'] }}
                                                        </template>

                                                        <template v-else-if="carrier[$store.state.translations.currentLanguage] && carrier[$store.state.translations.currentLanguage].length">
                                                            {{ $store.state.translations.translations['Unable to retrieve carrier instructions.'] }}
                                                        </template>

                                                        <template v-else>
                                                            <div class="row">
                                                                <div class="col-12">
                                                                    <strong v-if="getPortalContent('ReturnInstructionMessage')">
                                                                        {{ getPortalContent('ReturnInstructionMessage').title }}
                                                                    </strong>

                                                                    <strong v-else>
                                                                        {{ $store.state.translations.translations['Return instructions'] }}
                                                                    </strong>

                                                                    <div v-html="getPortalContent('ReturnInstructionMessage').content"
                                                                         v-if="getPortalContent('ReturnInstructionMessage')"></div>

                                                                    <p v-else>
                                                                        {{ $store.state.translations.translations['Please pack the returned products properly. If possible remove all old labels and tape from the carton.'] }}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </template>
                                                    </div>

                                                    <div class="col-12 col-md-6">
                                                        <div v-if="returnIsArchived">
                                                            {{ $store.state.translations.translations['The details for this return are not available anymore.'] }}
                                                        </div>

                                                        <template v-else-if="isLoadingDocuments">
                                                            {{ $store.state.translations.translations['Retrieving documents...'] }}
                                                        </template>

                                                        <template v-else-if="!foundDocuments">
                                                            <template v-if="allowShippingLabel">
                                                                {{ $store.state.translations.translations['Unable to retrieve shipment documents.'] }}
                                                            </template>

                                                            <template v-else>
                                                                {{ $store.state.translations.translations['The carrier will provide the shipping label.'] }}
                                                            </template>
                                                        </template>

                                                        <template v-else>
                                                            <div class="row mb-4" v-if="shippingLabelUrls">
                                                                <div class="col-sm-8 col-12">
                                                                    <strong v-if="getPortalContent('ShippingLabelDocument').title">
                                                                        {{ getPortalContent('ShippingLabelDocument').title }}
                                                                    </strong>

                                                                    <strong v-else>
                                                                        {{ $store.state.translations.translations['Download return label'] }}
                                                                    </strong>

                                                                    <div v-html="getPortalContent('ShippingLabelDocument').content"
                                                                         v-if="getPortalContent('ShippingLabelDocument')"></div>

                                                                    <p v-else>
                                                                        {{ $store.state.translations.translations['Please print the return label and stick it to the top of the box.'] }}
                                                                    </p>
                                                                </div>

                                                                <div class="col-sm-4 col-12">
                                                                    <div v-for="(shippingLabelUrl, key) in shippingLabelUrls" :key="key">
                                                                        <a :href="shippingLabelUrl" target="_blank" class="btn btn-primary my-2">
                                                                            <i class="icon-download"></i>

                                                                            <template v-if="shippingLabelUrls.length > 1">
                                                                                {{ key + 1 }}
                                                                            </template>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="row mb-4" v-if="noPrintLabelUrls">
                                                                <div class="col-sm-8 col-12">
                                                                    <strong v-if="getPortalContent('ShippingCodeDocument').title">
                                                                        {{ getPortalContent('ShippingCodeDocument').title }}
                                                                    </strong>

                                                                    <strong v-else>
                                                                        {{ $store.state.translations.translations['Download shipping code'] }}
                                                                    </strong>

                                                                    <div v-html="getPortalContent('ShippingCodeDocument').content"
                                                                         v-if="getPortalContent('ShippingCodeDocument')"></div>

                                                                    <p v-else>
                                                                        {{ $store.state.translations.translations['Show this code on your device for printing the label at the drop-off location.'] }}
                                                                    </p>
                                                                </div>

                                                                <div class="col-sm-4 col-12">
                                                                    <div v-for="(noPrintLabelUrl, key) in noPrintLabelUrls" :key="key">
                                                                        <a :href="noPrintLabelUrl" target="_blank" class="btn btn-primary my-2">
                                                                            <i class="icon-download"></i>

                                                                            <template v-if="noPrintLabelUrls.length > 1">
                                                                                {{ key + 1 }}
                                                                            </template>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="row mb-4" v-if="packingSlipUrl">
                                                                <div class="col-sm-8 col-12">
                                                                    <strong v-if="getPortalContent('PackingSlipDocument')">
                                                                        {{ getPortalContent('PackingSlipDocument').title }}
                                                                    </strong>

                                                                    <strong v-else>
                                                                        {{ $store.state.translations.translations['Download packing slip'] }}
                                                                    </strong>

                                                                    <div v-html="getPortalContent('PackingSlipDocument').content"
                                                                         v-if="getPortalContent('PackingSlipDocument')"></div>

                                                                    <p v-else>
                                                                        {{ $store.state.translations.translations['Print the packing slip and put it in the box.'] }}
                                                                    </p>
                                                                </div>

                                                                <div class="col-sm-4 col-12">
                                                                    <a :href="packingSlipUrl" target="_blank" class="btn btn-primary my-2">
                                                                        <i class="icon-download"></i>
                                                                    </a>
                                                                </div>
                                                            </div>

                                                            <div class="row" v-if="shippingInvoiceUrl">
                                                                <div class="col-sm-8 col-12">
                                                                    <strong v-if="getPortalContent('ShippingInvoiceDocument').title">
                                                                        {{ getPortalContent('ShippingInvoiceDocument').title }}
                                                                    </strong>

                                                                    <strong v-else>
                                                                        {{ $store.state.translations.translations['Download shipping invoice'] }}
                                                                    </strong>

                                                                    <div v-html="getPortalContent('ShippingInvoiceDocument').content"
                                                                         v-if="getPortalContent('ShippingInvoiceDocument').content"></div>

                                                                    <p v-else>
                                                                        {{ $store.state.translations.translations['Print 3 times. Put one copy in the box give 2 copies to the driver.'] }}
                                                                    </p>
                                                                </div>

                                                                <div class="col-sm-4 col-12">
                                                                    <a :href="shippingInvoiceUrl" target="_blank" class="btn btn-primary my-2">
                                                                        <i class="icon-download"></i>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </template>
                                                    </div>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>

                    <span class="label-title">
                        {{ $store.state.translations.translations['Return details'] }}
                    </span>

                    <div class="row my-3">
                        <div class="col-12">
                            <div class="accordion">
                                <div class="accordion-card py-0">
                                    <div class="card-body">
                                        <div v-if="isLoadingReturnModel">
                                            {{ $store.state.translations.translations['Loading return details...'] }}
                                        </div>

                                        <div v-else-if="returnModel && returnModel.length">
                                            {{ $store.state.translations.translations['Unable to retrieve return details.'] }}
                                        </div>

                                        <div v-else-if="returnIsArchived">
                                            {{ $store.state.translations.translations['The details for this return are not available anymore.'] }}
                                        </div>

                                        <template v-else>
                                            <ReturnDetails :return-model="returnModel"
                                                           :currency-symbol="currencySymbol"
                                                           :service-request-type="serviceRequestType"
                                                           :service-request-label="serviceRequestLabel"
                                                           :customer-costs="customerCosts"
                                            />
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <template v-if="!isEligibleForRefund">
                        <span class="label-title">
                            {{ $store.state.translations.translations['Shipping'] }}
                        </span>

                        <div class="row my-3">
                            <div class="col-12">
                                <div class="accordion height-100">
                                    <div class="accordion-card py-0 height-100-calculated">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-12 col-md-6">
                                                    <div v-if="returnIsArchived">
                                                        {{ $store.state.translations.translations['The details for this return are not available anymore.'] }}
                                                    </div>

                                                    <template v-else-if="isLoadingCarrier">
                                                        {{ $store.state.translations.translations['Loading instructions...'] }}
                                                    </template>

                                                    <template v-else-if="carrier[$store.state.translations.currentLanguage] && carrier[$store.state.translations.currentLanguage].length">
                                                        {{ $store.state.translations.translations['Unable to retrieve carrier instructions.'] }}
                                                    </template>

                                                    <template v-else>
                                                        <div class="row mb-4">
                                                            <template v-if="carrier[$store.state.translations.currentLanguage].Type.toLowerCase() === 'dropoff' && carrier[$store.state.translations.currentLanguage].DropoffInfoUrl">
                                                                <div class="col-12 col-sm-8">
                                                                    <strong>
                                                                        {{ $store.state.translations.translations['Carrier service'] }}
                                                                    </strong>
                                                                    <p>{{ carrier[$store.state.translations.currentLanguage].Label }}</p>
                                                                </div>

                                                                <div class="col-12 col-sm-4">
                                                                    <a :href="carrier[$store.state.translations.currentLanguage].DropoffInfoUrl"
                                                                       target="_blank" class="btn btn-primary my-2">
                                                                        <i class="icon-map-marker"></i>
                                                                    </a>
                                                                </div>
                                                            </template>

                                                            <template v-else>
                                                                <div class="col-12">
                                                                    <strong>
                                                                        {{ $store.state.translations.translations['Carrier service'] }}
                                                                    </strong>
                                                                    <p>{{ carrier[$store.state.translations.currentLanguage].Label }}</p>
                                                                </div>
                                                            </template>
                                                        </div>

                                                        <div class="row">
                                                            <div class="col-12">
                                                                <strong>
                                                                    {{ $store.state.translations.translations['Instructions'] }}
                                                                </strong>
                                                                <p>{{ carrier[$store.state.translations.currentLanguage].Instruction }}</p>
                                                            </div>
                                                        </div>
                                                    </template>
                                                </div>

                                                <div class="col-12 col-md-6">
                                                    <div v-if="returnIsArchived">
                                                        {{ $store.state.translations.translations['The details for this return are not available anymore.'] }}
                                                    </div>

                                                    <template v-else-if="isLoadingCarrier">
                                                        {{ $store.state.translations.translations['Loading instructions...'] }}
                                                    </template>

                                                    <template v-else-if="carrier[$store.state.translations.currentLanguage].RequiresBooking">
                                                        <template v-if="isSavingPreferredPickupDate">
                                                            <div class="row">
                                                                <div class="col-12">
                                                                    {{ $store.state.translations.translations['Confirming preferred pickup date...'] }}
                                                                </div>
                                                            </div>
                                                        </template>

                                                        <template v-else-if="!isValidatingPickupConfiguration && pickupConfigurationIsValid == false">
                                                            <div class="row">
                                                                <div class="col-12 text-danger">
                                                                    {{ $store.state.translations.translations['Something went wrong while retrieving the pickup details.'] }}
                                                                </div>
                                                            </div>
                                                        </template>

                                                        <template v-else-if="!isLoadingTimeZones">
                                                            <div class="row">
                                                                <div class="col-12">
                                                                    <strong v-if="hasSavedPreferredPickupDate && carrier[$store.state.translations.currentLanguage].CarrierListCode != 'VIRTUALCARRIER'">
                                                                        {{ $store.state.translations.translations['Confirmed pickup date'] }}
                                                                    </strong>

                                                                    <strong v-else>
                                                                        {{ $store.state.translations.translations['Preferred pickup date'] }}
                                                                    </strong>

                                                                    <p v-if="hasSavedPreferredPickupDate">
                                                                        {{ preferredPickupDate.date }}
                                                                    </p>

                                                                    <Datepicker v-model="selectedDate"
                                                                                :format="datepickerFormatter"
                                                                                :minDate="getMinDate()"
                                                                                :maxDate="getMaxDate()"
                                                                                :disabledWeekDays="getDisabledWeekDays()"
                                                                                :enableTimePicker="false"
                                                                                :autoApply="true"
                                                                                :placeholder="$store.state.translations.translations['Please confirm your preferred pickup date.']"
                                                                                class="mt-2 mb-4"
                                                                                v-else
                                                                    ></Datepicker>
                                                                </div>
                                                            </div>

                                                            <template v-if="pickupBookingOptions.AllowSpecifyTime == true">
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <strong>
                                                                            {{ $store.state.translations.translations['Preferred begin time'] }}
                                                                        </strong>

                                                                        <p v-if="hasSavedPreferredPickupDate">
                                                                            {{ preferredPickupDate.beginTime }}
                                                                        </p>

                                                                        <v-select v-else class="mt-2 mb-4" v-model="preferredPickupDate.beginTime"
                                                                                  :filterable="true" :searchable="true" :clearable="false"
                                                                                  :options="availableStartTimeRange"
                                                                                  :placeholder="$store.state.translations.translations['Please select your preferred begin time']">
                                                                        </v-select>
                                                                    </div>
                                                                </div>

                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <strong>
                                                                            {{ $store.state.translations.translations['Preferred end time'] }}
                                                                        </strong>

                                                                        <p v-if="hasSavedPreferredPickupDate">
                                                                            {{ preferredPickupDate.endTime }}
                                                                        </p>

                                                                        <v-select v-else class="mt-2 mb-4" v-model="preferredPickupDate.endTime"
                                                                                  :filterable="true" :searchable="true" :clearable="false"
                                                                                  :options="availableEndTimeRange"
                                                                                  :placeholder="$store.state.translations.translations['Please select your preferred end time']">
                                                                        </v-select>
                                                                    </div>
                                                                </div>

                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <strong>
                                                                            {{ $store.state.translations.translations['Time zone'] }}
                                                                        </strong>

                                                                        <p v-if="hasSavedPreferredPickupDate">
                                                                            {{ preferredPickupDate.timeZone }}
                                                                        </p>

                                                                        <v-select v-else class="mt-2 mb-4" :options="availableTimeZones" :clearable="false"
                                                                                  :reduce="timeZone => timeZone.code"
                                                                                  v-model="preferredPickupDate.timeZone">
                                                                        </v-select>
                                                                    </div>
                                                                </div>
                                                            </template>

                                                            <template v-if="hasSavedPreferredPickupDate && carrier[$store.state.translations.currentLanguage].CarrierListCode != 'VIRTUALCARRIER'">
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <strong>
                                                                            {{ $store.state.translations.translations['Confirmation'] }}
                                                                        </strong>

                                                                        <p v-if="preferredPickupDate.confirmationCode">
                                                                            {{ $store.state.translations.translations['Your return will be collected at the confirmed pickup date with confirmation code {confirmationCode}.'].replace('{confirmationCode}', preferredPickupDate.confirmationCode) }}
                                                                        </p>

                                                                        <p v-else>
                                                                            {{ $store.state.translations.translations['Your return will be collected at the confirmed pickup date.'] }}
                                                                        </p>
                                                                    </div>

                                                                    <div class="col-12">
                                                                        <a :href="preferredPickupDate.trackingUrl" target="_blank">
                                                                            {{ $store.state.translations.translations['Carrier tracking link'] }}
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </template>

                                                            <div class="row">
                                                                <div class="col-12">
                                                                    <InputFieldError attribute="global" :errors="errors"/>
                                                                </div>
                                                            </div>

                                                            <div class="row">
                                                                <div class="col-12 mt-3">
                                                                    <button class="btn btn-primary" @click.prevent="savePreferredPickupDate()" v-if="!hasSavedPreferredPickupDate">
                                                                        {{ $store.state.translations.translations['Confirm date'] }}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </template>
                                                    </template>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>

                    <template v-if="carrier[$store.state.translations.currentLanguage] && (!carrier[$store.state.translations.currentLanguage].RequiresBooking || (carrier[$store.state.translations.currentLanguage].RequiresBooking && hasSavedPreferredPickupDate)) && !isEligibleForRefund">
                        <span class="label-title">
                            {{ $store.state.translations.translations['Tracking'] }}
                        </span>

                        <div class="row my-3">
                            <div class="col-12">
                                <div class="accordion height-100">
                                    <div class="accordion-card py-0 height-100-calculated">
                                        <div class="card-body">
                                            <template v-if="returnIsArchived">
                                                <div class="row">
                                                    <div class="col-12">
                                                        {{ $store.state.translations.translations['The details for this return are not available anymore.'] }}
                                                    </div>
                                                </div>
                                            </template>

                                            <template v-else-if="isLoadingCarrier">
                                                <div class="row">
                                                    <div class="col-12">
                                                        {{ $store.state.translations.translations['Loading instructions...'] }}
                                                    </div>
                                                </div>
                                            </template>

                                            <template v-else>
                                                <div class="row">
                                                    <div class="col-12 col-md-6">
                                                        <div class="row mb-4">
                                                            <div class="col-12">
                                                                <strong v-if="getPortalContent('CommunicationEmail')">
                                                                    {{ getPortalContent('CommunicationEmail').title }}
                                                                </strong>

                                                                <strong v-else>
                                                                    {{ $store.state.translations.translations['Communication'] }}
                                                                </strong>

                                                                <div v-html="getPortalContent('CommunicationEmail').content"
                                                                     v-if="getPortalContent('CommunicationEmail')"></div>

                                                                <p v-else>
                                                                    {{ $store.state.translations.translations['We will keep you updated via email'] }}
                                                                </p>
                                                            </div>
                                                        </div>

                                                        <div class="row">
                                                            <div class="col-12">

                                                                <div class="row">
                                                                    <div class="col-7">
                                                                        <strong>
                                                                            {{ $store.state.translations.translations['Emails'] }}
                                                                        </strong>
                                                                    </div>

                                                                    <div class="col-5">
                                                                        <strong>
                                                                            {{ $store.state.translations.translations['Date sent'] }}
                                                                        </strong>
                                                                    </div>
                                                                </div>

                                                                <div class="row mb-2" v-for="activeEmailTemplate in activeEmailTemplates" :key="activeEmailTemplate.id">
                                                                    <div class="col-7">
                                                                        <template v-if="$store.state.translations.translations[activeEmailTemplate.name]">
                                                                            {{ $store.state.translations.translations[activeEmailTemplate.name] }}
                                                                        </template>

                                                                        <template v-else>
                                                                            {{ activeEmailTemplate.name }}
                                                                        </template>
                                                                    </div>

                                                                    <div class="col-5" v-if="!isLoadingSentShippingMails && sentEmailTemplates && sentEmailTemplates[activeEmailTemplate.id]">
                                                                        {{ sentEmailTemplates[activeEmailTemplate.id] }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-12 col-md-6">
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <strong v-if="getPortalContent('CommunicationTrack')">
                                                                    {{ getPortalContent('CommunicationTrack').title }}
                                                                </strong>

                                                                <strong v-else>
                                                                    {{ $store.state.translations.translations['Track'] }}
                                                                </strong>

                                                                <div v-html="getPortalContent('CommunicationTrack').content"
                                                                     v-if="getPortalContent('CommunicationTrack')"></div>

                                                                <p v-else>
                                                                    {{ $store.state.translations.translations['Please copy this link to track your return. Simply paste the link into the address bar of your browser to open our tracking page.'] }}
                                                                </p>
                                                            </div>

                                                            <div class="col-12">
                                                                <p v-if="isLoadingTrackingPage">
                                                                    {{ $store.state.translations.translations['Retrieving tracking url...'] }}
                                                                </p>

                                                                <template v-else-if="trackingPage">
                                                                    <input type="text" class="form-control" v-model="trackingPage"
                                                                           id="trackingPage" @click="copyLink()">

                                                                    <p v-if="hasCopiedTrackingPage" class="mt-3 link-copy-success">
                                                                        {{ $store.state.translations.translations['Link copied!'] }}
                                                                    </p>
                                                                </template>

                                                                <p v-else>
                                                                    {{ $store.state.translations.translations['No tracking link available.'] }}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>

                    <template v-if="!returnIsArchived && refundData">
                        <span class="label-title">
                            {{ $store.state.translations.translations['Instant refund'] }}
                        </span>

                        <div class="row my-3">
                            <div class="col-12">
                                <div class="accordion height-100">
                                    <div class="accordion-card py-0 height-100-calculated">
                                        <div class="card-body">
                                            <template v-if="isLoadingRefundData">
                                                <div class="row">
                                                    <div class="col-12">
                                                        {{ $store.state.translations.translations['Loading payment status...'] }}
                                                    </div>
                                                </div>
                                            </template>

                                            <template v-else>
                                                <div class="row">
                                                    <div class="col-12">
                                                        <ReveniInstantRefund :refund-data="refundData"
                                                                             @instant-refund-dismiss="dismissRefund()"
                                                                             @instant-refund-finish="getRefundData()"
                                                                             @instant-refund-status-undefined="dismissRefund()"
                                                        />
                                                    </div>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </section>
        </main>

        <SspFooter></SspFooter>
    </div>
</template>

<script>
    import SspHeader from '@/components/_Header.vue';
    import SspFooter from '@/components/_Footer.vue';
    import InstructionsSection from '@/components/_InstructionsSection.vue';
    import Datepicker from '@vuepic/vue-datepicker';
    import vSelect from 'vue-select';
    import InputFieldError from '@/components/_InputFieldError.vue';
    import ReveniInstantRefund from '@/components/_ReveniInstantRefund.vue';
    import ReturnDetails from '@/components/_ReturnDetails.vue';
    import _ from 'lodash';

    export default {
        components: {
            'SspHeader': SspHeader,
            'SspFooter': SspFooter,
            'InstructionsSection': InstructionsSection,
            'Datepicker': Datepicker,
            'v-select': vSelect,
            'InputFieldError': InputFieldError,
            'ReveniInstantRefund': ReveniInstantRefund,
            'ReturnDetails': ReturnDetails,
        },
        data() {
            return {
                returnId: this.$route.query.returnId,
                errors: {},
                isLoadingReturnModel: true,
                isLoadingCarrier: false,
                isLoadingDocuments: false,
                isLoadingSentShippingMails: true,
                isLoadingTimeZones: false,
                isLoadingRefundData: false,
                isValidatingPickupConfiguration: false,
                isSavingPreferredPickupDate: false,
                hasSavedPreferredPickupDate: false,
                isEligibleForRefund: false,
                foundDocuments: false,
                returnIsArchived: false,
                returnModel: null,
                carrier: [],
                documents: [],
                allowShippingLabel: false,
                shippingLabelUrls: null,
                packingSlipUrl: null,
                shippingInvoiceUrl: null,
                activeEmailTemplates: null,
                sentEmailTemplates: null,
                isLoadingTrackingPage: false,
                hasCopiedTrackingPage: false,
                trackingPage: this.$store.state.portalUrl + '#/trackReturn?returnId=' + this.$route.query.returnId,
                preferredPickupDate: {
                    date: null,
                    beginTime: null,
                    endTime: null,
                    timeZone: null,
                },
                selectedDate: null,
                availableTimeZones: null,
                availableTimes: ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'],
                pickupBookingOptions: {
                    AllowSameDay: null,
                    AllowSpecifyTime: null,
                    AllowedDays: null,
                    AvailableDaysRange: null,
                    MinimumStartTime: null,
                    MaximumEndTime: null,
                },
                pickupConfigurationIsValid: 0,
                refundData: null,
                currencySymbol: null,
                serviceRequestType: null,
                serviceRequestLabel: null,
                customerCosts: 0,
            };
        },
        created() {
            this.getRefundData();
            this.getReturn();
            this.getCarrier();
            this.getDocuments();
            this.getShippingMails();
            this.getSentShippingMails();
            this.getTrackingUrl();
            this.validatePickupConfiguration();
            this.getPreferredPickupDate();
        },
        mounted() {
            global.context.authorization.$on('switchedLanguage', () => {
                this.getCarrier();
            });
        },
        unmounted() {
            global.context.authorization.$off('switchedLanguage');
        },
        methods: {
            getPortalContent(section) {
                if (this.$store.state.portalContent.content && this.$store.state.portalContent.content[this.$store.state.translations.currentLanguage] && this.$store.state.portalContent.content[this.$store.state.translations.currentLanguage][section] && this.$store.state.portalContent.content[this.$store.state.translations.currentLanguage][section].RTN_PCT_ACTIVE != 0) {
                    return {
                        'title': this.$store.state.portalContent.content[this.$store.state.translations.currentLanguage][section].RTN_PCT_TITLE,
                        'content': this.$store.state.portalContent.content[this.$store.state.translations.currentLanguage][section].RTN_PCT_CONTENT,
                    };
                }

                return false;
            },
            getReturn() {
                const url = new URL(this.$store.state.portalUrl + 'return/getReturnDetails');
                url.searchParams.set('returnId', this.returnId);

                this.$http.get(url.href).then((data) => {
                    this.isLoadingReturnModel = false;
                    this.returnModel = data?.data?.data?.returnModel;
                    this.returnIsArchived = this.returnModel.IsArchived;
                    this.currencySymbol = data?.data?.data?.currencySymbol;
                    this.serviceRequestType = data?.data?.data?.serviceRequestType;
                    this.serviceRequestLabel = data?.data?.data?.serviceRequestLabel;
                    this.customerCosts = data?.data?.data?.customerCosts ?? 0;
                });
            },
            getCarrier() {
                this.isLoadingCarrier = true;

                this.$store.dispatch('carrierModule/getSelectedCarrier', this.returnId).then((data) => {
                    this.carrier[this.$store.state.translations.currentLanguage] = data;
                    this.isLoadingCarrier = false;

                    if (data) {
                        this.pickupBookingOptions = data['PickupBookingOptions'];
                        this.allowShippingLabel = data['AllowShippingLabel'];
                    }
                });
            },
            getDocuments() {
                this.isLoadingDocuments = true;

                this.$store.dispatch('shipmentModule/getLabelUrls', this.returnId).then((data) => {
                    this.shippingLabelUrls = data.shippingLabelUrls;
                    this.noPrintLabelUrls = data.noPrintLabelUrls;
                    this.packingSlipUrl = data.packingSlipUrl;
                    this.shippingInvoiceUrl = data.shippingInvoiceUrl;

                    this.foundDocuments = this.shippingLabelUrls || this.packingSlipUrl || this.shippingInvoiceUrl || this.noPrintLabelUrls;
                    this.isLoadingDocuments = false;
                });
            },
            getTrackingUrl() {
                this.isLoadingTrackingPage = true;

                this.$store.dispatch('shipmentModule/getTrackingPage', this.returnId).then((data) => {
                    this.trackingPage = data;
                    this.isLoadingTrackingPage = false;
                });
            },
            getShippingMails() {
                this.$store.dispatch('shipmentModule/getActiveShippingMails', this.returnId).then((data) => {
                    this.activeEmailTemplates = data;
                });
            },
            getSentShippingMails() {
                this.isLoadingSentShippingMails = true;

                this.$store.dispatch('shipmentModule/getSentShippingMails', this.returnId).then((data) => {
                    this.sentEmailTemplates = data;
                    this.isLoadingSentShippingMails = false;
                });
            },
            copyLink() {
                let trackingPageInput = document.querySelector('#trackingPage');
                trackingPageInput.select();

                if (document.execCommand('copy')) {
                    this.hasCopiedTrackingPage = true;
                }
            },
            datepickerFormatter(date) {
                let d = new Date(date);
                return d.getUTCDate() + '-' + (d.getUTCMonth() + 1) + '-' + d.getUTCFullYear();
            },
            getMinDate() {
                let minDate = new Date();

                /* Disable up to today, unless same day is allowed. */
                if (this.pickupBookingOptions.AllowSameDay == false || this.currentDayAvailableStartTimeRange.length === 0) {
                    minDate.setDate(minDate.getDate() + 1);
                }

                return minDate;
            },
            getMaxDate() {
                let maxDate = new Date();

                /* Set available amount of days */
                if (parseInt(this.pickupBookingOptions.AvailableDaysRange) > 0) {
                    maxDate.setDate(maxDate.getDate() + parseInt(this.pickupBookingOptions.AvailableDaysRange));
                } else {
                    maxDate.setDate(maxDate.getDate() + 14);
                }

                return maxDate;
            },
            getDisabledWeekDays() {
                let days = [];

                /* Disable specific days */
                if (this.pickupBookingOptions.AllowedDays) {
                    if (!this.pickupBookingOptions.AllowedDays.includes('sunday')) {
                        days.push(0);
                    }

                    if (!this.pickupBookingOptions.AllowedDays.includes('monday')) {
                        days.push(1);
                    }

                    if (!this.pickupBookingOptions.AllowedDays.includes('tuesday')) {
                        days.push(2);
                    }

                    if (!this.pickupBookingOptions.AllowedDays.includes('wednesday')) {
                        days.push(3);
                    }

                    if (!this.pickupBookingOptions.AllowedDays.includes('thursday')) {
                        days.push(4);
                    }

                    if (!this.pickupBookingOptions.AllowedDays.includes('friday')) {
                        days.push(5);
                    }

                    if (!this.pickupBookingOptions.AllowedDays.includes('saturday')) {
                        days.push(6);
                    }
                }

                return days;
            },
            getPreferredPickupDate() {
                this.isLoadingTimeZones = true;

                const url = new URL(this.$store.state.portalUrl + 'shipment/getPreferredPickupDate');
                url.searchParams.set('returnId', this.returnId);

                this.$http.get(url.href).then((data) => {
                    this.isLoadingTimeZones = false;

                    if (data.data.data.preferredPickupDate) {
                        this.hasSavedPreferredPickupDate = data.data.data.preferredPickupDate.date;
                        this.preferredPickupDate = data.data.data.preferredPickupDate;

                        this.availableTimeZones = _.toPairs(data.data.data.preferredPickupDate.availableTimeZones).map(a => {
                            return {code: a[0], label: a[1]};
                        });

                        /* Retrieve the timezone from the client and preselect it. */
                        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                        if (timeZone && !this.preferredPickupDate.timeZone) {
                            this.preferredPickupDate.timeZone = timeZone;
                        }
                    }
                });
            },
            savePreferredPickupDate() {
                if (!this.preferredPickupDate.date || (this.pickupBookingOptions.AllowSpecifyTime == true && (!this.preferredPickupDate.beginTime || !this.preferredPickupDate.endTime || !this.preferredPickupDate.timeZone))) {
                    this.errors = {global: this.$store.state.translations.translations['Please enter your pickup booking preferences']};
                    return false;
                }

                this.errors = {};
                this.isSavingPreferredPickupDate = true;

                const url = new URL(this.$store.state.portalUrl + 'shipment/savePreferredPickupDate');
                url.searchParams.set('returnId', this.returnId);

                const params = new URLSearchParams();
                params.append('ShipmentPickup[RTN_SPP_PREF_DATE]', this.preferredPickupDate.date);
                params.append('ShipmentPickup[RTN_SHP_PREF_DATE_TIMEZONE]', this.preferredPickupDate.timeZone);
                params.append('ShipmentPickup[RTN_SPP_PREF_BEGIN_TIME]', this.preferredPickupDate.beginTime);
                params.append('ShipmentPickup[RTN_SPP_PREF_END_TIME]', this.preferredPickupDate.endTime);

                this.$http.post(url.href, params).then((data) => {
                    this.isSavingPreferredPickupDate = false;

                    if (data.data.errorCode && data.data.errorCode === 'BP001') {
                        this.errors = {global: this.$store.state.translations.translations['Pickup is not available for the selected date. Please choose a different date.']};
                    } else if (data.data.data.errors) {
                        this.errors = {global: data.data.data.errors};
                    } else {
                        this.hasSavedPreferredPickupDate = true;

                        this.$store.commit('trackReturns/clear');

                        this.getReturn();
                        this.getPreferredPickupDate();
                        this.getSentShippingMails();
                    }
                });
            },
            formatDate(date) {
                return date.getUTCDate() + '-' + (date.getUTCMonth() + 1) + '-' + date.getUTCFullYear();
            },
            validatePickupConfiguration() {
                this.isValidatingPickupConfiguration = true;

                const url = new URL(this.$store.state.portalUrl + 'shipment/validatePickupConfiguration');
                url.searchParams.set('returnId', this.returnId);

                this.$http.get(url.href).then((data) => {
                    this.isValidatingPickupConfiguration = false;

                    if (typeof data.data.data.configurationValidation !== 'undefined') {
                        this.pickupConfigurationIsValid = data.data.data.configurationValidation;
                    }
                });
            },
            dismissRefund() {
                this.isEligibleForRefund = false;
            },
            getRefundData() {
                this.isLoadingRefundData = true;
                this.refundData = null;
                this.isEligibleForRefund = false;

                const url = new URL(this.$store.state.portalUrl + 'refund/getRefundData');
                url.searchParams.set('returnId', this.returnId);

                this.$http.get(url.href).then((data) => {
                    this.isLoadingRefundData = false;

                    if (data && data.data && data.data.data && data.data.data.refund) {
                        if (data.data.data.refund.status === 'incomplete') {
                            this.isEligibleForRefund = true;
                        }

                        this.refundData = data.data.data.refund;
                    }
                });
            }
        },
        computed: {
            selectedStartTime() {
                return this.preferredPickupDate.beginTime;
            },
            availableTimeRange() {
                let availableTimes = [];

                let minimumStartTime = this.pickupBookingOptions.MinimumStartTime ? this.pickupBookingOptions.MinimumStartTime.replace(':00:00', ':00') : this.availableTimes[0];
                let maximumEndTime = this.pickupBookingOptions.MaximumEndTime ? this.pickupBookingOptions.MaximumEndTime.replace(':00:00', ':00') : this.availableTimes[this.availableTimes.length - 1];

                for (let i in this.availableTimes) {
                    if (this.availableTimes[i] >= minimumStartTime && this.availableTimes[i] <= maximumEndTime) {
                        availableTimes.push(this.availableTimes[i]);
                    }
                }

                return availableTimes;
            },
            currentDayAvailableStartTimeRange() {
                let availableTimes = [];
                let availableTimeRangeWithoutLast = this.availableTimeRange.slice(0, -1);
                let today = new Date();
                let currentTime = today.getHours() + ':00';

                for (let i in availableTimeRangeWithoutLast) {
                    if (currentTime < availableTimeRangeWithoutLast[i]) {
                        availableTimes.push(availableTimeRangeWithoutLast[i]);
                    }
                }

                return availableTimes;
            },
            availableStartTimeRange() {
                let availableTimes = this.availableTimeRange.slice(0, -1);

                if (this.selectedDate) {
                    let today = new Date();

                    if (this.formatDate(today) === this.formatDate(this.selectedDate)) {
                        availableTimes = this.currentDayAvailableStartTimeRange;
                    }
                }

                return availableTimes;
            },
            availableEndTimeRange() {
                let availableTimes = [];

                if (this.selectedStartTime) {
                    for (let i in this.availableTimeRange) {
                        if (this.selectedStartTime < this.availableTimeRange[i]) {
                            availableTimes.push(this.availableTimeRange[i]);
                        }
                    }
                } else {
                    availableTimes = this.availableTimeRange;
                }

                return availableTimes;
            }
        },
        watch: {
            selectedDate(value) {
                let d = new Date(value);
                this.preferredPickupDate.date = this.formatDate(d);

                this.preferredPickupDate.beginTime = null;
                this.preferredPickupDate.endTime = null;
            },
            selectedStartTime() {
                if (!this.hasSavedPreferredPickupDate) {
                    this.preferredPickupDate.endTime = null;
                }
            }
        }
    }
</script>

<style scoped>
    .height-100 {
        height: 100%;
    }

    .height-100-calculated {
        height: calc(100% - 15px);
    }

    .link-copy-success {
        color: #35CDAB;
    }
</style>
