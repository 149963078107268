<template>
    <div class="page">
        <SspHeader activeMenuItem="create" />

        <main class="main">
            <InstructionsSection contentBlockSection="OrderPage" progressBarCurrentStep="order"/>

            <OrderOverview v-if="!orderIsSaving"></OrderOverview>

            <form action="#">
                <div class="container sm">
                    <div v-if="orderIsSaving">
                        <div class="row">
                            <div class="col-md-6 pr-lg-4">
                                <div class="form-group w-100">
                                    {{ $store.state.translations.translations['Saving...'] }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else-if="maxOrdersReached">
                        <div class="row">
                            <div class="col-md-6 pr-lg-4">
                                <div class="form-group w-100">
                                    {{ $store.state.translations.translations['Maximum orders reached'] }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row" v-if="initLoaded && !orderIsSaving && !maxOrdersReached && merchantSetupsLoaded">
                        <div class="col-md-6 pr-lg-4">
                            <div class="form-group w-100">
                                <label for="order-number">{{ field1Name }}</label>
                                <input id="order-number" v-model="order.Number" ref="ordernumField" v-bind:placeholder="field1Placeholder" type="text" class="form-control">
                                <InputFieldError attribute="RTN_ODR_NUMBER" :errors="errors"/>
                            </div>
                        </div>

                        <div class="col-md-6 pl-lg-4">
                            <div class="form-group w-100">
                                <label for="p-code">{{ field2Name }}</label>
                                <input id="p-code" v-model="order.ExternalCode" v-bind:placeholder="field2Placeholder" type="text" class="form-control" :disabled="externalCodeDisabled">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container sm">
                    <InputFieldError attribute="general" :errors="errors"/>

                    <div class="col-12 d-flex justify-content-center my-3" v-if="!orderIsSaving">
                        <button type="button" class="btn md btn-secondary m-3" @click="previous()">
                            {{ $store.state.translations.translations['Back'] }}
                        </button>

                        <button type="button" name="button" @click="saveOrder()" class="btn md btn-primary m-1 m-md-3">
                            {{ $store.state.translations.translations['Next'] }}
                        </button>
                    </div>
                </div>
            </form>
        </main>

        <SspFooter></SspFooter>
    </div>
</template>

<script>
    import OrderOverview from '@/components/_OrderOverview';
    import SspHeader from '@/components/_Header.vue';
    import SspFooter from '@/components/_Footer.vue';
    import InstructionsSection from '@/components/_InstructionsSection.vue';
    import InputFieldError from '@/components/_InputFieldError.vue';

    export default {
        components: {
            'OrderOverview': OrderOverview,
            'SspHeader': SspHeader,
            'SspFooter': SspFooter,
            'InstructionsSection': InstructionsSection,
            'InputFieldError': InputFieldError,
        },
        computed: {},
        data() {
            return {
                initLoaded: false,
                merchantSetupsLoaded: false,
                returnId: this.$route.query.returnId,
                merchantSetupId: this.$route.query.merchantSetupId,
                order: {Number: '', ExternalCode: ''},
                orderIsSaving: false,
                field1Name: '',
                field2Name: '',
                field1Placeholder: '',
                field2Placeholder: '',
                ordersLoaded: false,
                maxOrdersReached: false,
                params: null,
                errors: null,
                orderExternalCode: null,
                externalCodeDisabled: false,
            }
        },
        created() {
            this.loadLabels();

            this.$store.dispatch('mcpModule/getAll').then(() => {
                this.merchantSetupsLoaded = true;
                this.params = this.$store.state.mcpModule.merchantSetups[this.$store.state.translations.currentLanguage][this.merchantSetupId].parameters;
                this.hasConnector = this.$store.state.mcpModule.merchantSetups[this.$store.state.translations.currentLanguage][this.merchantSetupId].hasConnector;
                this.orderExternalCode = this.$options.filters.capitalize(this.$store.state.mcpModule.merchantSetups[this.$store.state.translations.currentLanguage][this.merchantSetupId].orderRetrievalParams.authenticateCustomerAddressField);

                if(this.orderExternalCode == 'Email' || this.orderExternalCode == 'CustomerReference' || this.orderExternalCode == 'ExternalReference') {
                    if (this.$store.state.isLoggedIn) {
                        this.$store.dispatch('getCurrentUser').then((user) => {
                            if(user.Email && this.orderExternalCode == 'Email') {
                                this.order.ExternalCode = user.Email;
                                this.externalCodeDisabled = true;
                            }
                            if(user.ExternalRelationNumber && (this.orderExternalCode == 'CustomerReference' || this.orderExternalCode == 'ExternalReference')) {
                                this.order.ExternalCode = user.ExternalRelationNumber;
                                this.externalCodeDisabled = true;
                            }
                        });
                    }
                }

                this.$nextTick(() => {
                    if (this.initLoaded && !this.orderIsSaving && !this.maxOrdersReached && this.merchantSetupsLoaded && this.$refs.ordernumField) {
                        this.$refs.ordernumField.focus();
                    }
                });
            });

            this.$store.dispatch('orders/getAll', this.returnId);
        },
        mounted() {
            global.context.authorization.$on('switchedLanguage', () => {
                this.initLoaded = false;
                this.$store.commit('OCformdataModule/clear');
                this.loadLabels();
            });
        },
        unmounted() {
            global.context.authorization.$off('switchedLanguage');
        },
        methods: {
            editOrder(orderObj) {
                this.$router.push({
                    path: 'productsFromOrderConnector',
                    query: {returnId: this.returnId, orderId: orderObj.OrderId, merchantSetupId: this.merchantSetupId}
                });
            },
            saveOrder() {
                this.errors = {};

                this.orderIsSaving = true;
                let url = new URL(this.$store.state.portalUrl + 'order/save');
                url.searchParams.set('returnId', this.returnId);

                //post data as form
                let params = new URLSearchParams();
                params.append('Order[Number]', this.order.Number);
                params.append('Order[ExternalCode]', this.order.ExternalCode);

                this.$http.post(url.href, params).then((data) => {
                    if (data.data.data.errors) {
                        this.orderIsSaving = false;
                        this.errors = data.data.data.errors;

                        if (this.errors && typeof this.errors !== 'object' && !Array.isArray(this.errors)) {
                            this.errors = {general: this.errors};
                        }
                    } else {
                        let orderId = data.data.data.orderModel.OrderId;
                        this.$store.commit('orders/add', {order: data.data.data.orderModel, returnId: this.returnId});
                        this.$router.push({
                            path: 'productsFromOrderConnector',
                            query: {returnId: this.returnId, orderId: orderId, merchantSetupId: this.merchantSetupId}
                        });
                    }
                });
            },
            loadLabels() {
                this.$store.dispatch('OCformdataModule/getFieldNames', this.$route.query.merchantSetupId).then((data) => {
                    this.field1Name = data.field1Value;
                    this.field2Name = data.field2Value;
                    this.field1Placeholder = data.field1Placeholder;
                    this.field2Placeholder = data.field2Placeholder;
                    this.initLoaded = true;

                    this.$nextTick(() => {
                        if (this.initLoaded && !this.orderIsSaving && !this.maxOrdersReached && this.merchantSetupsLoaded && this.$refs.ordernumField) {
                            this.$refs.ordernumField.focus();
                        }
                    });
                });
            },
            previous() {
                this.$router.push({
                    name: 'MerchantSetupPage'
                });
            }
        },
        filters: {
            capitalize(value) {
                if (!value) {
                    return '';
                }

                value = value.toString();
                return value.charAt(0).toUpperCase() + value.slice(1);
            }
        }
    }
</script>

<style>

</style>
