<template>
    <div>
        <main class="main">
            <p>
                Fetching page / logging in
            </p>
        </main>
    </div>
</template>

<script>
    import mcpnext from '@/mixins/mcpnext.js';
    import languageSelect from '@/mixins/languageSelect.js';

    export default {
        mixins: [
            mcpnext,
            languageSelect
        ],
        data() {
            return {
                message: this.$route.query.msg,
                token: this.$route.query.keyToken,
                appBaseUrl: this.$store.state.portalUrl
            };
        },
        created() {
            const url = new URL(this.appBaseUrl + 'externalLogin/auth');
            url.searchParams.set('token', this.token);
            url.searchParams.set('message', this.message);

            this.$http.get(url.href).then((data) => {
                let type = data.data.data.type;

                switch (type) {
                    case 'create_as_customer_no_login':
                    case 'create_as_customer_with_login': {
                        const returnModel = data.data.data.returnModel;
                        let self = this;

                        if (type === 'create_as_customer_with_login') {
                            const user = data.data.data.user;
                            this.$store.commit('setCurrentUser', user);
                        }

                        this.selectLanguage(returnModel.Language);

                        global.context.authorization.$on('switchedLanguage', () => {
                            if (returnModel.Status === 'draft') {
                                this.$store.dispatch('mcpModule/getAll', true).then(() => {
                                    self.mcpnext(returnModel, true);
                                });
                            } else if (returnModel.Status === 'logistics/approved') {
                                this.$router.push({
                                    path: '/addAddress',
                                    query: {
                                        returnId: returnModel.ReturnId,
                                        orderId: returnModel.OrderId,
                                        merchantSetupId: returnModel.MerchantSetupId
                                    }
                                });
                            } else {
                                this.$router.push({
                                    path: '/trackReturn',
                                    query: {
                                        returnId: returnModel.ReturnId,
                                    }
                                });
                            }
                        });
                        break;
                    }

                    case 'finish_shipment_no_login':
                    case 'finish_shipment_with_login': {
                        const orderId = data.data.data.orderId;
                        const returnId = data.data.data.returnId;
                        const mcpId = data.data.data.merchantSetupId;
                        const returnStatus = data.data.data.returnStatus;
                        const whoSubmitShipment = data.data.data.whoSubmitShipment;

                        if (type === 'finish_shipment_with_login') {
                            const user = data.data.data.user;
                            this.$store.commit('setCurrentUser', user);
                        }

                        if (returnStatus && returnStatus === 'logistics/requested') {
                            if (whoSubmitShipment && whoSubmitShipment === 'agent') {
                                this.$router.push({
                                    name: 'TrackReturn',
                                    query: {returnId: returnId}
                                });
                            } else {
                                this.$router.push({
                                    name: 'ShipInstructions',
                                    query: {returnId: returnId}
                                });
                            }
                        } else {
                            this.$router.push({
                                name: 'AddAddress',
                                query: {returnId: returnId, orderId: orderId, merchantSetupId: mcpId}
                            });
                        }
                        break;
                    }

                    case 'payment_completed_redirect_no_login':
                    case 'payment_completed_redirect_with_login':
                        if (type === 'payment_completed_redirect_with_login') {
                            let user = data.data.data.user;
                            this.$store.commit('setCurrentUser', user);
                        }

                        this.$router.push({
                            name: 'PaymentStatus',
                            query: {
                                returnId: data.data.data.returnId,
                                orderId: data.data.data.orderId,
                                merchantSetupId: data.data.data.merchantSetupId
                            }
                        });
                        break;

                    case 'track_return_no_login':
                    case 'track_return_with_login':
                        if (type === 'track_return_with_login') {
                            let user = data.data.data.user;
                            this.$store.commit('setCurrentUser', user);
                        }

                        this.$router.push({
                            path: '/trackReturn',
                            query: {
                                returnId: data.data.data.returnId,
                            }
                        });
                        break;
                }
            });
        },
    }
</script>

<style>

</style>
