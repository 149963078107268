<template>
    <div class="page">
        <SspHeader activeMenuItem="ship"/>

        <main class="main mb-5">
            <InstructionsSection contentBlockSection="ShipmentStatusPage" progressBarCurrentModule="ship" progressBarCurrentStep="createLabel"/>

            <div class="container container-full-width-md">
                <template v-if="isSubmittingForm">
                    <div class="container sm">
                        <div class="row">
                            <div class="col-12 text-center">
                                <div class="spinner spinner--steps icon-spinner" aria-hidden="true"></div>
                            </div>

                            <div class="col-12 col-sm-12 text-center">
                                <h1>
                                    {{ $store.state.translations.translations['Creating your shipping label and instructions'] }}
                                </h1>

                                <p class="mt-3">
                                    {{ $store.state.translations.translations['We are creating your shipping label. This might take a while depending on the chosen carrier. Please be patient.'] }}
                                </p>

                                <p v-if="selectedCarrierLabel">
                                    {{ $store.state.translations.translations['Creating a return shipping label for {carrier service}...'].replace('{carrier service}', selectedCarrierLabel) }}
                                </p>
                            </div>
                        </div>
                    </div>
                </template>

                <div class="row" v-else-if="Object.keys(errors).length > 0">
                    <div class="col-12">
                        <InputFieldError attribute="global" :errors="errors"/>
                    </div>
                </div>
            </div>
        </main>

        <SspFooter></SspFooter>
    </div>
</template>

<script>
    import SspHeader from '@/components/_Header.vue';
    import SspFooter from '@/components/_Footer.vue';
    import InstructionsSection from '@/components/_InstructionsSection.vue';
    import InputFieldError from '@/components/_InputFieldError.vue';

    export default {
        components: {
            'SspHeader': SspHeader,
            'SspFooter': SspFooter,
            'InstructionsSection': InstructionsSection,
            'InputFieldError': InputFieldError,
        },
        data() {
            return {
                returnId: this.$route.query.returnId,
                isSubmittingForm: false,
                errors: {},
                submitResult: '',
                returnReference: null,
                selectedCarrierLabel: null,
            };
        },
        created() {
            this.getCarrier();
            this.submitReturn();
        },
        methods: {
            next() {
                if (this.submitResult === 'submit-failed') {
                    this.$router.push({
                        path: 'shipSubmitFailed',
                        query: {
                            returnReference: this.returnReference,
                        }
                    });

                    return false;
                }

                this.$router.push({
                    path: 'shipInstructions',
                    query: {
                        returnId: this.returnId,
                    }
                });
            },
            getCarrier() {
                this.isLoadingCarrier = true;
                const url = new URL(this.$store.state.portalUrl + 'shipment/getCarrier');
                url.searchParams.set('returnId', this.returnId);

                this.$http.get(url.href).then((data) => {
                    this.isLoadingCarrier = false;
                    this.selectedCarrierLabel = data.data.data.carrier.Label;
                });
            },
            submitReturn() {
                this.isSubmittingForm = true;

                const url = new URL(this.$store.state.portalUrl + 'shipment/submitReturn');
                const params = new URLSearchParams();
                params.append('ReturnModel[ID]', this.returnId);

                this.$http.post(url.href, params).then((data) => {
                    this.isSubmittingForm = false;

                    if (data.data.data.errors) {
                        this.errors = {global: data.data.data.errors};
                    } else if (data.data.data.redirect) {
                        this.$router.push({
                            name: 'ShipInstructions',
                            query: {
                                returnId: this.returnId,
                            }
                        });
                    } else {
                        this.submitResult = data.data.data.returnResult;
                        this.returnReference = data.data.data.returnModel.ReturnReference;

                        if (this.submitResult !== 'submit-failed') {
                            this.isSubmittingForm = true;
                            this.$store.commit('shipReturns/delete', data.data.data.returnModel.ReturnId);
                            this.$store.commit('trackReturns/add', data.data.data.returnModel);
                        }

                        this.next();
                    }
                });
            }
        },
        filters: {
            capitalize: function (value) {
                if (!value) {
                    return '';
                }

                value = value.toString();
                return value.charAt(0).toUpperCase() + value.slice(1);
            }
        }
    }
</script>

<style scoped>

</style>
