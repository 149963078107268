<template>
    <div class="container sm mb-5">
        <template v-if="$store.state.orders.orders && $store.state.orders.orders[returnId] && Object.keys($store.state.orders.orders[returnId]).length">
            <label>
                {{ $store.state.translations.translations['Continue with your open return'] }}
            </label>

            <div v-if="!$store.state.orders.loaded">
                {{ $store.state.translations.translations['Loading orders...'] }}
            </div>

            <div v-else-if="isEmpty($store.state.orders.orders[returnId])">
                {{ $store.state.translations.translations['No open orders'] }}
            </div>

            <template v-else>
                <div class="accordion" v-for="order in $store.state.orders.orders[returnId]" :key="order.OrderId">
                    <div class="accordion-card">
                        <template v-if="ordersBeingDeleted.indexOf(order.OrderId) !== -1">
                            <div class="accordion-card-header">
                                <div class="accordion-card-header-td">
                                    {{ $store.state.translations.translations['Deleting order...'] }}
                                </div>
                            </div>
                        </template>

                        <template v-else>
                            <div class="accordion-card-header">
                                <div class="accordion-card-header-td">
                                    {{order.Number}}
                                </div>

                                <div class="accordion-card-header-td">
                                    <a href="#" v-on:click.prevent="editOrder(order)">
                                        {{ $store.state.translations.translations['Edit'] }}
                                    </a>
                                </div>

                                <div class="accordion-card-header-td ">
                                    <span class="text-danger" v-on:click.prevent="deleteOrder(order.OrderId)">
                                        {{ $store.state.translations.translations['Delete'] }}
                                    </span>
                                </div>

                                <div class="accordion-card-header-td">
                                    <a href="#" v-on:click.prevent="next(order.OrderId)">
                                        {{ $store.state.translations.translations['Add products'] }}
                                    </a>
                                </div>
                            </div>
                        </template>
                    </div>

                    <InputFieldError attribute="general" :errors="errors[order.OrderId]" />
                </div>
            </template>
        </template>
    </div>
</template>

<script>
    import _ from 'lodash';
    import InputFieldError from '@/components/_InputFieldError.vue';

    export default {
        components: {
            'InputFieldError': InputFieldError,
        },
        data() {
            return {
                returnId: this.$route.query.returnId,
                merchantSetupId: this.$route.query.merchantSetupId,
                ordersBeingDeleted: [],
                errors: {},
            }
        },
        created() {

        },
        methods: {
            isEmpty(value) {
                return _.isEmpty(value);
            },
            editOrder(orderObj) {
                this.$parent.editOrder(orderObj);
            },
            deleteOrder(orderId) {
                this.ordersBeingDeleted.push(orderId);

                const url = new URL(this.$store.state.portalUrl + 'order/delete');
                url.searchParams.set('orderId', orderId);

                this.$http.post(url.href).then((data) => {
                    if (data?.data?.data?.errors) {
                        const deleteIndex = this.ordersBeingDeleted.indexOf(orderId);

                        if (deleteIndex > -1) {
                            this.ordersBeingDeleted.splice(deleteIndex, 1);
                        }

                        this.errors[orderId] = {general: data.data.data.errors};
                    } else {
                        this.$store.commit('orders/delete', {OrderId: orderId, returnId: this.returnId});
                    }
                });
            },
            next(orderId) {
                if (this.$store.state.mcpModule.merchantSetups[this.$store.state.translations.currentLanguage][this.merchantSetupId].hasConnector) {
                    this.$router.push({
                        path: 'productsFromOrderConnector',
                        query: {returnId: this.returnId, orderId: orderId, merchantSetupId: this.merchantSetupId}
                    });
                } else {
                    this.$router.push({
                        path: 'products',
                        query: {returnId: this.returnId, orderId: orderId, merchantSetupId: this.merchantSetupId}
                    });
                }
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    .text-danger {
        cursor: pointer;
    }
</style>
