import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import axios from './classes/Axios';
import VueAxios from 'vue-axios';

import eventBus from './event-bus';
global.context = {};
global.context.authorization = eventBus;

import BootstrapVue3 from 'bootstrap-vue-3';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css';
import 'vue-select/dist/vue-select.css';
import '@vuepic/vue-datepicker/dist/main.css';
import 'vue-search-select/dist/VueSearchSelect.css';

import '@/assets/sass/style.scss';

import WindowEventManager from './classes/WindowEventManager';

createApp(App)
    .use(router)
    .use(store)
    .use(BootstrapVue3)
    .use(VueAxios, axios)
    .use(WindowEventManager.plugin)
    .mount('#app');
