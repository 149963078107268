<template>
    <div class="page">
        <SspHeader activeMenuItem="create"/>

        <InstructionsSection contentBlockSection="SelectSettlementProposalPage" progressBarCurrentStep="confirmation"></InstructionsSection>

        <form action="#">
            <div class="container sm">
                <SettlementProposalSelect @has-saved-settlement-proposal="afterSelectingSettlementProposal" />
            </div>

            <div class="container sm">
                <div class="col-12 d-flex flex-column flex-md-row align-items-center justify-content-center pt-3 pt-md-4 mb-5">
                    <button type="button" name="button" class="btn md btn-primary m-1 m-md-3" @click="next()" :disabled="!hasSelectedSettlementProposal">
                        {{ $store.state.translations.translations['Confirm'] }}
                    </button>
                </div>
            </div>
        </form>

        <SspFooter></SspFooter>
    </div>
</template>


<script>
    import SspHeader from '@/components/_Header.vue';
    import SspFooter from '@/components/_Footer.vue';
    import InstructionsSection from '@/components/_InstructionsSection.vue';
    import SettlementProposalSelect from '@/components/_SettlementProposalSelect.vue';

    export default {
        name: 'CheckoutPage',
        components: {
            'SspHeader': SspHeader,
            'SspFooter': SspFooter,
            'InstructionsSection': InstructionsSection,
            'SettlementProposalSelect': SettlementProposalSelect,
        },
        data() {
            return {
                hasSelectedSettlementProposal: false,
            };
        },
        created() {

        },
        methods: {
            afterSelectingSettlementProposal() {
                this.hasSelectedSettlementProposal = true;
            },
            next() {
                this.$router.push({
                    name: 'SubmitForApproval',
                    query: {submitResult: this.$route.query.submitResult, returnReference: this.$route.query.returnReference}
                });
            },
        }
    }
</script>

<style scoped>

</style>
