<template>
    <div class="page">
        <SspHeader activeMenuItem="create"/>

        <main class="main">
            <InstructionsSection contentBlockSection="OrderPage" progressBarCurrentStep="order"/>

            <OrderOverview v-if="!orderIsSaving && !orderId"></OrderOverview>

            <form @submit.prevent>
                <div class="container sm">
                    <div v-if="orderIsSaving">
                        <div class="row">
                            <div class="col-md-6 pr-lg-4">
                                <div class="form-group w-100">
                                    {{ $store.state.translations.translations['Saving...'] }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="maxOrdersReached">
                        <div class="row">
                            <div class="col-md-6 pr-lg-4">
                                <div class="form-group w-100">
                                    {{ $store.state.translations.translations['Maximum orders reached'] }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <InputFieldError attribute="attributes" :errors="errors"/>
                        </div>

                        <template v-if="isOrderNumberRequired() && !this.params.skipOrderPage">
                            <div class="col-md-6 pr-lg-4" v-if="!orderIsSaving && !maxOrdersReached">
                                <div class="form-group w-100">
                                    <label for="order-number">
                                        {{ $store.state.translations.attributeLabels['RTN_ODR_NUMBER'] }}
                                    </label>

                                    <input v-model="order.Number"
                                           ref="ordernumField"
                                           type="text"
                                           class="form-control"
                                           :placeholder="$store.state.translations.placeHolders['RTN_ODR_NUMBER']"
                                    >
                                </div>

                                <InputFieldError attribute="RTN_ODR_NUMBER" :errors="errors"/>
                            </div>
                        </template>

                        <template v-if="returnsLoaded && !returnCurrencyCode && merchantSetupsLoaded">
                            <div class="col-md-6 pr-lg-4" v-if="!orderIsSaving && !currencyIsSaving">
                                <div class="form-group w-100">
                                    <label>
                                        {{ $store.state.translations.translations['Order currency'] }}
                                    </label>

                                    <model-list-select
                                        id="country-selection"
                                        :list="currencies"
                                        option-value="code"
                                        option-text="label"
                                        v-model="selectedCurrencyCode"
                                        :placeholder="$store.state.translations.translations['Select the currency you paid with for your order']"
                                        required
                                    >
                                    </model-list-select>

                                    <InputFieldError attribute="RTN_ODR_CURRENCY_CODE" :errors="errors"/>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>

                <template v-if="isOrderNumberRequired() && !this.params.skipOrderPage">
                    <div class="container sm">
                        <InputFieldError attribute="general" :errors="errors"/>

                        <div class="col-12 d-flex justify-content-center my-3" v-if="!orderIsSaving">
                            <button type="button" class="btn md btn-secondary m-3" @click="previous()">
                                {{ $store.state.translations.translations['Back'] }}
                            </button>

                            <button type="button" name="button" @click="saveOrder()" class="btn md btn-primary m-1 m-md-3">
                                {{ $store.state.translations.translations['Next'] }}
                            </button>
                        </div>
                    </div>
                </template>

                <template v-else-if="selectCurrency === true || selectCurrency === 'true'">
                    <div class="container sm">
                        <InputFieldError attribute="general" :errors="errors"/>

                        <div class="col-12 d-flex justify-content-center my-3" v-if="!currencyIsSaving">
                            <button type="button" class="btn md btn-secondary m-3" @click="previous()">
                                {{ $store.state.translations.translations['Back'] }}
                            </button>

                            <button type="button" name="button" @click="saveCurrencyCode()" class="btn md btn-primary m-1 m-md-3">
                                {{ $store.state.translations.translations['Next'] }}
                            </button>
                        </div>
                    </div>
                </template>
            </form>
        </main>

        <SspFooter></SspFooter>
    </div>
</template>

<script>
    import OrderOverview from '@/components/_OrderOverview';
    import SspHeader from '@/components/_Header.vue';
    import SspFooter from '@/components/_Footer.vue';
    import InstructionsSection from '@/components/_InstructionsSection.vue';
    import InputFieldError from '@/components/_InputFieldError.vue';
    import {ModelListSelect} from 'vue-search-select';
    import _ from 'lodash';

    export default {
        components: {
            'OrderOverview': OrderOverview,
            'SspHeader': SspHeader,
            'SspFooter': SspFooter,
            'InstructionsSection': InstructionsSection,
            'InputFieldError': InputFieldError,
            'ModelListSelect': ModelListSelect,
        },
        data() {
            return {
                merchantSetupsLoaded: false,
                returnId: this.$route.query.returnId,
                orderId: this.$route.query.orderId,
                selectCurrency: this.$route.query.selectCurrency,
                merchantSetupId: this.$route.query.merchantSetupId,
                order: {
                    OrderId: null,
                    Number: ''
                },
                orderIsSaving: false,
                currencyIsSaving: false,
                selectedCurrencyCode: null,
                currencies: null,
                maxOrdersReached: false,
                params: null,
                errors: null,
                returnsLoaded: false,
            }
        },
        created() {
            this.$store.dispatch('mcpModule/getAll').then(() => {
                this.merchantSetupsLoaded = true;
                this.params = this.$store.state.mcpModule.merchantSetups[this.$store.state.translations.currentLanguage][this.merchantSetupId].parameters;
                this.currencies = _.toPairs(this.$store.state.mcpModule.merchantSetups[this.$store.state.translations.currentLanguage][this.merchantSetupId].currencies).map(c => {
                    return {code: c[0], label: c[1]};
                });

                this.$nextTick(() => {
                    if (this.isOrderNumberRequired() && this.$refs.ordernumField) {
                        this.$refs.ordernumField.focus();
                    }
                });
            });

            this.$store.dispatch('draftReturns/getAll').then(() => {
                this.returnsLoaded = true;
            });

            this.$store.dispatch('orders/getAll', this.returnId);
        },
        methods: {
            clearCurrentOrder() {
                this.order = {
                    OrderId: null,
                    Number: ''
                }
            },
            editOrder(orderObj) {
                this.order = orderObj;
                this.$refs.ordernumField.focus();
            },
            saveOrder: function () {
                this.errors = null;
                this.orderIsSaving = true;
                let url = new URL(this.$store.state.portalUrl + 'order/save');
                url.searchParams.set('returnId', this.returnId);

                if (this.order.OrderId)
                    url.searchParams.set('orderId', this.order.OrderId);

                //post data as form
                let params = new URLSearchParams();
                params.append('Order[Number]', this.order.Number);

                if (this.selectedCurrencyCode) {
                    params.append('Order[CurrencyCode]', this.selectedCurrencyCode);
                }

                this.$http.post(url.href, params).then((data) => {
                    this.clearCurrentOrder();

                    if (!data.data.data.errors) {
                        let orderId = data.data.data.orderModel.OrderId;
                        this.$store.commit('orders/add', {order: data.data.data.orderModel, returnId: this.returnId});

                        if (this.selectedCurrencyCode) {
                            this.$store.commit('draftReturns/setAttributeValue', [this.returnId, 'CurrencyCode', this.selectedCurrencyCode]);
                        }

                        this.$router.push({
                            path: 'products',
                            query: {returnId: this.returnId, orderId: orderId, merchantSetupId: this.merchantSetupId}
                        });
                    } else {
                        this.orderIsSaving = false;
                        this.errors = data.data.data.errors;

                        if (this.errors && typeof this.errors !== 'object' && !Array.isArray(this.errors)) {
                            this.errors = {general: this.errors};
                        }
                    }
                });
            },
            saveCurrencyCode() {
                this.orderIsSaving = true;
                let url = new URL(this.$store.state.portalUrl + 'order/saveCurrencyCode');
                url.searchParams.set('returnId', this.returnId);
                url.searchParams.set('orderId', this.orderId);

                let params = new URLSearchParams();
                params.append('Order[CURRENCY_CODE]', this.selectedCurrencyCode);

                this.$http.post(url.href, params).then((data) => {
                    this.orderIsSaving = false;

                    if (data.data.data.errors) {
                        this.errors = data.data.data.errors;
                    } else {
                        this.$store.commit('draftReturns/setAttributeValue', [this.returnId, 'CurrencyCode', this.selectedCurrencyCode]);

                        this.$router.push({
                            path: 'products',
                            query: {returnId: this.returnId, orderId: this.orderId, merchantSetupId: this.merchantSetupId}
                        });
                    }
                });
            },
            previous() {
                this.$router.push({
                    name: 'MerchantSetupPage'
                });
            },
            isOrderNumberRequired() {
                return this.params && (this.params.multiOrderAllowed || (!this.params.multiOrderAllowed && this.$store.state.orders.orders[this.returnId] && Object.keys(this.$store.state.orders.orders[this.returnId]).length === 0));
            },
        },
        computed: {
            returnCurrencyCode() {
                return this.$store.state.draftReturns?.returns?.[this.returnId]?.['CurrencyCode'];
            }
        }
    }
</script>

<style>

</style>
