<template>
    <div class="container-fluid overflow-x-hidden">
        <div class="row">
            <div class="col-lg-6 block-left">
                <InputFieldError attribute="attributes" :errors="errors"/>

                <nav class="navbar lang-absolute-container">
                    <LanguageSelector/>
                </nav>

                <div class="block-left-content">
                    <router-link class="back-link" :to="{name: 'Login'}">
                        &lt; {{	$store.state.translations.translations['Back']  }}
                    </router-link>

                    <h1 v-if="pagestatus !== 'response'">
                        {{ $store.state.translations.translations['Forgot password?'] }}
                    </h1>

                    <span class="simple-text" v-if="pagestatus !== 'response'">
                        {{ $store.state.translations.translations['Receive an email to reset your password.'] }}
                    </span>

                    <div class="mt-3" v-if="pagestatus === 'loadingResponse'">
                        {{ $store.state.translations.translations['Loading response...'] }}
                    </div>

                    <form action="#" class="form" v-else-if="pagestatus === 'initial'">
                        <div class="form-group">
                            <label for="email">
                                {{ $store.state.translations.attributeLabels['RTN_RTN_EMAIL'] }}
                            </label>

                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="icon-icon-mail"></span>
                                </div>

                                <input id="email" type="email" class="form-control" v-model="user.email">
                            </div>
                        </div>

                        <div class="d-flex justify-content-end">
                            <button type="button"
                                    name="button"
                                    class="btn btn-primary md"
                                    @click.prevent="forgotPassword()"
                            >
                                {{ $store.state.translations.translations['Reset password'] }}
                            </button>
                        </div>
                    </form>

                    <div v-else-if="pagestatus === 'response'">
                        <span class="simple-text">
                            {{ $store.state.translations.translations['Password change request mail has been send'] }}
                        </span>
                    </div>
                </div>
            </div>

            <div class="col-6 block-right">

            </div>
        </div>
    </div>
</template>

<script>
    import LanguageSelector from '@/components/_LanguageSelector.vue';
    import InputFieldError from '@/components/_InputFieldError.vue';

    export default {
        components: {
            'LanguageSelector': LanguageSelector,
            'InputFieldError': InputFieldError,
        },
        data() {
            return {
                user: {
                    email: ''
                },
                errors: {},
                pagestatus: 'initial',
                responseMessage: '',
            }
        },
        created() {

        },
        mounted() {
            this.responseMessage =  this.$store.state.translations.translations['An email has been sent to reset your password.'];
        },
        methods: {
            forgotPassword() {
                this.pagestatus = 'loadingResponse';

                const url = new URL(this.$store.state.portalUrl + 'user/forgotPassword');
                const params = new URLSearchParams();
                params.append('user[email]', this.user.email);

                this.$http.post(url.href, params).then((data) => {
                    this.pagestatus = 'response';

                    if (data.data.data.errors) {
                        this.errors = data.data.data.errors;
                    } else {
                        this.responseMessage = data.data.data.message;
                    }
                });
            }
        }
    }
</script>

<style>

</style>
