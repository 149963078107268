<template>
    <div id="app" :style="cssVariables">
        <router-view/>
    </div>
</template>

<script>
import ColorGenerator from './classes/ColorGenerator';

export default {
    data() {
        return {
            colorPrimary: '#308FFF',
            logoWidth: 'auto',
            logoHeight: 'auto',
            logoMaxWidth: '150px',
            logoMaxHeight: '70px',
        }
    },
    computed: {
        /**
         * Creates an object with css variables. The result is then added to the div above.
         * This is used to implement custom portal colors.
         *
         * @returns object
         */
        cssVariables() {
            this.setStylingVariables();

            return {
                '--color-primary': this.colorPrimary,
                '--color-primary-lighter': ColorGenerator.instance.lightenDarkenColor(this.colorPrimary, 10),
                '--color-primary-lightest': ColorGenerator.instance.lightenDarkenColor(this.colorPrimary, 20),
                '--color-primary-darker': ColorGenerator.instance.lightenDarkenColor(this.colorPrimary, -10),
                '--color-primary-darkest': ColorGenerator.instance.lightenDarkenColor(this.colorPrimary, -20),
                '--color-primary-a50': ColorGenerator.instance.opacityColor(this.colorPrimary, 50),
                '--color-primary-a35': ColorGenerator.instance.opacityColor(this.colorPrimary, 35),
                '--color-primary-a25': ColorGenerator.instance.opacityColor(this.colorPrimary, 25),
                '--logo-width': this.logoWidth,
                '--logo-height': this.logoHeight,
                '--logo-max-width': this.logoMaxWidth,
                '--logo-max-height': this.logoMaxHeight,
            };
        }
    },
    methods: {
        setStylingVariables() {
            const portal = this.$store.state.portal;

            if (portal && portal.attributes.RTN_PTL_STYLING) {
                const portalStyling = JSON.parse(portal.attributes.RTN_PTL_STYLING);

                if (portalStyling['$accent-color']) {
                    this.colorPrimary = portalStyling['$accent-color'];
                }

                if (portalStyling['$logo-width'] && portalStyling['$logo-height']) {
                    /* If both width and height are set, use the values to update the logo. */
                    this.logoWidth = portalStyling['$logo-width'] + 'px';
                    this.logoHeight = portalStyling['$logo-height'] + 'px';
                    this.logoMaxWidth = 'initial';
                    this.logoMaxHeight = 'initial';
                }
            }
        }
    }
}
</script>

<style>

</style>
