<template>
    <div class="container-fluid overflow-x-hidden">
        <div class="row">
            <div class="col-lg-6 block-left">
                <nav class="navbar lang-absolute-container">
                    <LanguageSelector/>
                </nav>

                <div class="block-left-content">
                    <router-link class="back-link" :to="{ name: 'Login' }">
                        &lt; {{ $store.state.translations.translations['Back'] }}
                    </router-link>

                    <h1>
                        {{ $store.state.translations.translations['Welcome'] }}
                    </h1>

                    <span class="simple-text">
                        {{ $store.state.translations.translations['Create your account for the {accountname} returns portal.'].replace('{accountname}', $store.state.portal.attributes.RTN_PTL_NAME) }}
                    </span>

                    <p v-if="isSavingUser" class="mt-4">
                        {{ $store.state.translations.translations['Registering your account...'] }}
                    </p>

                    <p class="mt-3" v-if="showCreationSuccessMessage">
                        {{ $store.state.translations.translations['an email has been sent to {email} with a link to confirm the creation of your account.'].replace('{email}', user.email) }}
                    </p>

                    <form action="#" class="form" v-else-if="!isSavingUser">
                        <div class="form-group">
                            <label for="">
                                {{ $store.state.translations.attributeLabels['RTN_RTN_EMAIL'] }}
                            </label>

                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="icon-icon-mail"></span>
                                </div>

                                <input type="email" class="form-control" v-model="user.email" :disabled="isSavingUser">
                            </div>

                            <InputFieldError attribute="RTN_USR_EMAIL" :errors="errors"/>
                        </div>

                        <div class="form-group">
                            <label for="">
                                {{ $store.state.translations.translations['Password'] }}
                            </label>

                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="icon-icon-key"></span>
                                </div>

                                <input :type="passwordFieldType"
                                       v-model="user.password"
                                       @keyup="checkPasswordMatch()"
                                       class="form-control password"
                                       :disabled="isSavingUser"
                                >

                                <div class="input-group-append">
                                    <span class="icon-icon-eye cursor-pointer" @click="togglePasswordShow()"></span>
                                </div>
                            </div>

                            <InputFieldError attribute="RTN_USR_PASSWORD" :errors="errors"/>
                        </div>

                        <div class="form-group">
                            <label for="">
                                {{ $store.state.translations.translations['Repeat password'] }}
                            </label>

                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="icon-icon-key"></span>
                                </div>

                                <input :type="passwordFieldType"
                                       v-model="user.repeatPassword"
                                       @keyup="checkPasswordMatch()"
                                       class="form-control password"
                                       :disabled="isSavingUser"
                                >

                                <div class="input-group-append password">
                                    <span class="icon-check" v-if="passwordFieldMatch"></span>
                                    <span class="icon-icon-eye cursor-pointer" @click="togglePasswordShow()"></span>
                                </div>
                            </div>

                            <InputFieldError attribute="repeatPassword" :errors="errors"/>
                        </div>

                        <div class="form-group">
                            <label for="">
                                {{ $store.state.translations.attributeLabels['RTN_LNA_ATTN_FIRST_NAME'] }}
                            </label>

                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="icon-icon-mail"></span>
                                </div>

                                <input type="text" class="form-control" v-model="user.firstname" :disabled="isSavingUser">
                            </div>

                            <InputFieldError attribute="RTN_USR_FIRSTNAME" :errors="errors"/>
                        </div>

                        <div class="form-group">
                            <label for="">
                                {{ $store.state.translations.attributeLabels['RTN_LNA_ATTN_FAMILY_NAME'] }}
                            </label>

                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="icon-icon-mail"></span>
                                </div>

                                <input type="text" class="form-control" v-model="user.lastname" :disabled="isSavingUser">
                            </div>

                            <InputFieldError attribute="RTN_USR_LASTNAME" :errors="errors"/>
                        </div>

                        <div class="form-group">
                            <label for="">
                                {{ $store.state.translations.attributeLabels['RTN_LNA_PHONE'] }}
                            </label>

                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="icon-icon-mail"></span>
                                </div>

                                <input type="text" class="form-control" v-model="user.phone" :disabled="isSavingUser">
                            </div>

                            <InputFieldError attribute="RTN_USR_PHONE" :errors="errors"/>
                        </div>

                        <div class="d-flex justify-content-end">
                            <button type="button"
                                    name="button"
                                    class="btn btn-secondary md"
                                    @click.prevent="register()"
                                    :disabled="isSavingUser"
                                    v-if="$store.state.portal.parameters.userCreationEnabled"
                            >
                                {{ $store.state.translations.translations['Create account'] }}
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            <div class="col-6 block-right">

            </div>
        </div>
    </div>
</template>


<script>
    import LanguageSelector from '@/components/_LanguageSelector.vue';
    import InputFieldError from '@/components/_InputFieldError.vue';

    export default {
        name: 'RegisterPage',
        components: {
            'LanguageSelector': LanguageSelector,
            'InputFieldError': InputFieldError,
        },
        data() {
            return {
                errors: {},
                showCreationSuccessMessage: false,
                user: {
                    firstname: '',
                    lastname: '',
                    phone: '',
                    email: '',
                    password: '',
                    repeatPassword: ''
                },
                passwordFieldMatch: false,
                passwordFieldType: 'password',
                isSavingUser: false,
            }
        },
        created() {

        },
        methods: {
            register() {
                if (this.isSavingUser) {
                    return false;
                }

                this.isSavingUser = true;

                const url = this.$store.state.portalUrl + 'user/register';
                const params = new URLSearchParams();
                params.append('user[email]', this.user.email);
                params.append('user[password]', this.user.password);
                params.append('user[firstname]', this.user.firstname);
                params.append('user[lastname]', this.user.lastname);
                params.append('user[repeatPassword]', this.user.repeatPassword);
                params.append('user[phone]', this.user.phone);

                this.$http.post(url, params).then((data) => {
                    this.isSavingUser = false;

                    /**
                     * If there are any errors, store them in the errors variable.
                     */
                    if (typeof data.data.data.errors !== 'undefined') {
                        this.errors = data.data.data.errors;
                    }
                    /**
                     * If the account creation was successful, show a message that the user needs to confirm their
                     * account by email.
                     */
                    else if (typeof data.data.data.showCreationSuccessMessage !== 'undefined') {
                        this.showCreationSuccessMessage = data.data.data.showCreationSuccessMessage;
                    }
                    /**
                     * There are no errors or other messages, this means the user is free to be logged in after
                     * registering their account.
                     */
                    else {
                        this.isSavingUser = true;
                        this.$store.commit('setCurrentUser', data.data.data.user);
                        this.$router.push({
                            name: 'Index'
                        });
                    }
                });
            },
            togglePasswordShow() {
                this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
            },
            checkPasswordMatch() {
                this.passwordFieldMatch = this.user.password !== '' && this.user.password == this.user.repeatPassword;
            }
        }
    }
</script>

<style>

</style>
