<template>
    <div>
        <div class="container container-full-width-md container-md-desktop pt-3">
            <template v-if="productCountOnOrder.length === 0">
                <div class="ps-2">
                    {{ $store.state.translations.translations['There are no more products on your order'] }}
                </div>
            </template>

            <template v-else>
                <div class="accordion-head">
                    <div class="accordion-head-td first"></div>

                    <template v-if="showModelNumber !== false">
                        <div class="accordion-head-td second">
                            {{ $store.state.translations.attributeLabels['RTN_PDT_MODEL_NUMBER'] }}
                        </div>

                        <div class="accordion-head-td third">
                            {{ $store.state.translations.attributeLabels['RTN_PDT_DESCRIPTION'] }}
                        </div>

                        <div class="accordion-head-td forth">
                            {{ $store.state.translations.attributeLabels['RTN_PDT_QUANTITY_ORDERED'] }}
                        </div>
                    </template>

                    <template v-else>
                        <div class="accordion-head-td lg">
                            {{ $store.state.translations.attributeLabels['RTN_PDT_DESCRIPTION'] }}
                        </div>

                        <div class="accordion-head-td forth">
                            {{ $store.state.translations.attributeLabels['RTN_PDT_QUANTITY_ORDERED'] }}
                        </div>
                    </template>

                    <div class="accordion-head-td first"></div>
                </div>

                <template v-if="$store.state.OC.loaded">
                    <template v-for="(article, index) in $store.state.OC.OCProducts[orderId]" :key="index">
                        <template v-if="!article.ProductId && article.excludeFromOrderConnector !== true">
                            <OCArticle v-bind:article="article" :params="params" :index="index"></OCArticle>
                        </template>
                    </template>
                </template>
            </template>
        </div>
    </div>
</template>
<script>
    import OCArticle from './_OCArticle.vue';

    export default {
        components: {
            'OCArticle': OCArticle,
        },
		props: [
			'params'
		],
        data() {
            return {
                returnId: this.$route.query.returnId,
                merchantSetupId: this.$route.query.merchantSetupId,
                orderId: this.$route.query.orderId
            }
        },
        computed: {
            productCountOnOrder() {
                return this.$store.state.OC.OCProducts[this.orderId].filter((key) => {
                    return key.ProductId == null && key.excludeFromOrderConnector !== true;
                });
            },
            showModelNumber() {
                return this.$store.state.mcpModule?.merchantSetups?.[this.$store.state.translations?.currentLanguage]?.[this.$route.query?.merchantSetupId]?.parameters?.showModelNumber;
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>
