<template>
    <div class="page">
        <SspHeader activeMenuItem="track"/>

        <main class="main">
            <section class="request-shipment-section">
                <div class="container sm">
                    <h1>
                        {{ $store.state.translations.translations['Track your returns'] }}
                    </h1>
                </div>
            </section>

			<div class="container sm">
				<div class="row mb-3" >
					<div class="col-6">
						<div class="btn btn-primary mt-1 me-2" @click="toggleShowFilters()">
							<template v-if="!showFilters">
                                {{ $store.state.translations.translations['Show filters'] }}
                            </template>

							<template v-else>
                                {{ $store.state.translations.translations['Hide filters'] }}
                            </template>
						</div>

                        <div class="btn btn-primary mt-1" @click="clearFilters()" v-if="showFilters">
                            {{ $store.state.translations.translations['Clear filters'] }}
                        </div>
					</div>
				</div>
			</div>

			<div class="container sm" v-if="initLoaded && showFilters">
				<div class="form-group w-100">
					<div class="row mb-3" >
						<div class="col-6">
							<label>
								{{ $store.state.translations.translations['Search returns'] }}:
							</label>
						</div>
					</div>

					<div class="row mb-3" >
						<div class="col-6">
                            {{ $store.state.translations.attributeLabels['RTN_MERCHANT_SETUP_ID'] }}
						</div>

						<div class="col-6">
                            {{ $store.state.translations.attributeLabels['RTN_STATUS'] }}
						</div>
					</div>

					<div class="row mb-3" >
						<div class="col-6">
                            <v-select
                                :placeholder="$store.state.translations.translations['Choose return policy']"
                                :options="returnPolicyList"
                                :clearable="false"
                                :filterable="false"
                                :reduce="mcp => mcp.value"
                                :searchable="false"
                                v-model="filter.mcpId"
                            >
                            </v-select>
						</div>

						<div class="col-6">
                            <v-select
                                :placeholder="$store.state.translations.translations['Choose status']"
                                :options="statusList"
                                :clearable="false"
                                :filterable="false"
                                :searchable="false"
                                v-model="filter.status"
                            >
                            </v-select>
						</div>
					</div>

					<div class="row" >
						<div class="col-6">
							{{ $store.state.translations.translations['Start creation date'] }}
						</div>

						<div class="col-6">
							{{ $store.state.translations.translations['End creation date'] }}
						</div>
					</div>

					<div class="row mb-3" >
						<div class="col-6">
                            <Datepicker v-model="filter.startDate"
                                        :format="datepickerFormatter"
                                        :enableTimePicker="false"
                                        :autoApply="true"
                                        :placeholder="$store.state.translations.translations['Choose start creation date']"
                                        class="mt-2"
                            ></Datepicker>
						</div>

						<div class="col-6">
                            <Datepicker v-model="filter.endDate"
                                        :format="datepickerFormatter"
                                        :enableTimePicker="false"
                                        :autoApply="true"
                                        :placeholder="$store.state.translations.translations['Choose end creation date']"
                                        class="mt-2"
                            ></Datepicker>
						</div>
					</div>


					<div class="row mb-3" >
						<div class="col-6">
							{{ $store.state.translations.translations['Search term'] }}
						</div>

						<div class="col-6" v-if="isAdmin">
							{{ $store.state.translations.translations['User'] }}
						</div>
					</div>

					<div class="row mb-3">
						<div class="col-6">
							<input v-model="searchTerm" type="text" class="form-control" :placeholder="$store.state.translations.translations['Enter a search term']">
						</div>

						<div class="col-6" v-if="isAdmin">
                            <v-select
                                :placeholder="$store.state.translations.translations['Choose user']"
                                :options="userList"
                                :clearable="false"
                                :filterable="false"
                                :reduce="option => option.value"
                                :searchable="false"
                                v-model="filter.userId"
                            >
							</v-select>
						</div>
					</div>

					<div class="row mb-3">
						<div class="col-6">
							<div class="btn btn-primary mt-1" @click="searchReturns">
								{{ $store.state.translations.translations['Search'] }}
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="container sm mt-3" v-if="returnPagination.hasRetrievedAll && returns.length == 0">
				{{ $store.state.translations.translations['No results found'] }}
			</div>

			<div class="container sm" v-else-if="returnPagination.isLoading && !initLoaded">
				{{ $store.state.translations.translations['Loading returns...'] }}
			</div>

			<div class="container sm" v-else-if="returns.length > 0">
                <div class="custom-table">
                    <div class="custom-table-head">
                        <div class="custom-table-head-cell one">
                            {{ $store.state.translations.attributeLabels['RTN_RTN_REFERENCE'] }}
                        </div>

                        <div class="custom-table-head-cell two">
                            {{ $store.state.translations.attributeLabels['RTN_MERCHANT_SETUP_ID'] }}
                        </div>

                        <div class="custom-table-head-cell three">
                            {{ $store.state.translations.attributeLabels['RTN_RTN_CREATION'] }}
                        </div>

                        <div class="custom-table-head-cell four">
                            {{ $store.state.translations.attributeLabels['RTN_STATUS'] }}
                        </div>

                        <div class="custom-table-head-cell five">

                        </div>
                    </div>

                    <div class="custom-table-body" v-for="rtn in returns" :key="rtn.ReturnId">
                        <div class="custom-table-body-row">
                            <div class="custom-table-td one">
                                {{ rtn.ReturnReference }}
                            </div>

                            <div class="custom-table-td two">
                                {{ rtn.MerchantSetupLabel }}
                            </div>

                            <div class="custom-table-th-mobile">
                                {{ translations.createdAt }}
                            </div>

                            <div class="custom-table-th-mobile">
                                {{ translations.status }}
                            </div>

                            <div class="custom-table-td three">
                                {{ rtn.CreatedAt.substring(0, 10) }}
                            </div>

                            <div class="custom-table-td four">
                                <span class="text-success">{{ rtn.Status}}</span>
                            </div>

                            <div class="custom-table-td five">
                                <div class="row">
                                    <div class="col-12">
                                        <a type="button"
                                           class="btn btn-primary mt-1"
                                           ref="btnTrack"
                                           :style="{ 'width': btnWidth }"
                                           :href="'#/trackReturn?returnId=' + rtn.ReturnId + '&overview=track'"
                                        >
                                            {{ $store.state.translations.translations['Track'] }}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container sm" v-if="returnPagination.isLoading">
                    {{ $store.state.translations.translations['Loading returns...'] }}
                </div>

                <div class="d-flex justify-content-center mt-3" v-if="!returnPagination.hasRetrievedAll && !returnPagination.isLoading" ref="loadReturns">
                    <button type="button" class="btn btn-primary py-2" @click.prevent="getReturns()">
                        {{ $store.state.translations.translations['Show more returns'] }}
                    </button>
                </div>
            </div>
        </main>

        <SspFooter></SspFooter>
    </div>
</template>

<script>
    import SspHeader from '@/components/_Header.vue';
    import SspFooter from '@/components/_Footer.vue';
	import vSelect from 'vue-select';
    import Datepicker from '@vuepic/vue-datepicker';

	export default {
        name: 'TrackPage',
        components: {
            'SspHeader': SspHeader,
            'SspFooter': SspFooter,
			'v-select': vSelect,
			'Datepicker': Datepicker,
        },
        data() {
            return {
                isLoading: false,
                translations: {
                    status: this.$store.state.translations.attributeLabels['RTN_STATUS'],
                    createdAt: this.$store.state.translations.attributeLabels['RTN_RTN_CREATION'],
                },
                btnWidth: null,
				appBaseUrl: this.$store.state.portalUrl,
				isScrollEventOnCooldown: false,
				returns: [],
				searchTerm: '',
				initLoaded: false,
				initDataLoaded: false,
				userList: [],
				isAdmin: 0,
				returnPolicyList: [],
				statusList: [],
				oldSearchTerm: '',
				showFilters: false,
				filter: {
					userId: false,
					mcpId: false,
					status: false,
					startDate: '',
					endDate: ''
				},
				returnPagination: {
					isLoading: false,
					hasRetrievedAll: false,
					limit: 10,
					offset: 0,
				},
            };
        },
		created() {
            const url = new URL(this.$store.state.portalUrl + 'history/getFormData');
            url.searchParams.set('type', 'track');

            this.$http.get(url.href).then((data) => {
                this.isAdmin = data.data.data.isAdmin;
                const users = data.data.data.userList;
                const mcps = data.data.data.returnPolicyList;

                for (let i in mcps) {
                    this.returnPolicyList.push({
                        label: mcps[i].MERCHANT_SETUP_WEBSHOP_LABEL,
                        value: mcps[i].MERCHANT_SETUP_ID
                    });
                }

                for (let i in users) {
                    let label = users[i].RTN_USR_FIRSTNAME + ' ' + users[i].RTN_USR_LASTNAME + ' (' + users[i].RTN_USR_EMAIL + ')';

                    if (users[i].RTN_USR_DELETED !== null) {
                        label += ' (inactive)';
                    }

                    this.userList.push({
                        label: label,
                        value: users[i].RTN_USR_ID
                    });
                }

                this.statusList = data.data.data.statusList;
                this.initDataLoaded = true;
                this.getReturns();
            });

			window.addEventListener('scroll', this.performLazyLoading);
		},
		unmounted() {
			window.removeEventListener('scroll', this.performLazyLoading);
		},
        methods: {
			getReturns() {
				if (this.returnPagination.hasRetrievedAll || this.returnPagination.isLoading) {
					// all returns have been retreived
					return;
				}

				this.returnPagination.isLoading = true;

				const url = new URL(this.$store.state.portalUrl + 'history/get');
				url.searchParams.set('offset', this.returnPagination.offset);
                url.searchParams.set('type', 'track');

                if (this.searchTerm !== '')
                    url.searchParams.set('searchTerm', this.searchTerm);
                if (this.filter.startDate)
                    url.searchParams.set('startDate', this.datepickerFormatter(this.filter.startDate));
                if (this.filter.endDate)
                    url.searchParams.set('endDate', this.datepickerFormatter(this.filter.endDate));
                if (this.filter.userId)
                    url.searchParams.set('userId', this.filter.userId);
                if (this.filter.mcpId)
                    url.searchParams.set('mcpId', this.filter.mcpId);
                if (this.filter.status)
                    url.searchParams.set('status', this.filter.status);

                this.$http.get(url.href).then((data) => {
                    this.returnPagination.isLoading = false;

                    if (data.data.data) {
                        this.returnPagination.offset += data.data.data.length;

                        if (data.data.data.length < this.returnPagination.limit) {
                            this.returnPagination.hasRetrievedAll = true;
                        }

                        const keys = Object.keys(data.data.data.returns).reverse();

                        for (let i in keys) {
                            this.returns.push(data.data.data.returns[keys[i]]);
                        }
                    }
                    if (this.initDataLoaded) {
                        this.initDataLoaded = false;
                        this.initLoaded = true;
                    }
                });
            },
            performLazyLoading() {
				if (this.isScrollEventOnCooldown) {
					return;
				}

				this.performReturnLazyLoading();
			},
            clearFilters() {
                this.searchTerm = '';
                this.oldSearchTerm = '';
                this.filter = {
                    userId: false,
                    mcpId: false,
                    status: false,
                    startDate: '',
                    endDate: ''
                }
            },
			performReturnLazyLoading() {
				if (this.$refs && this.$refs['loadReturns']) {
					let rect = this.$refs['loadReturns'].getBoundingClientRect();

					if (rect.bottom > 0 &&
						rect.right > 0 &&
						rect.left < (window.innerWidth || document.documentElement.clientWidth) &&
						rect.top < (window.innerHeight || document.documentElement.clientHeight)) {
						this.getReturns();
					}
				}
			},
			searchReturns() {
				this.oldSearchTerm = this.searchTerm;
				this.returnPagination.offset = 0;
				this.returnPagination.hasRetrievedAll = false;
				this.returns = [];
				this.getReturns();
			},
			datepickerFormatter(date) {
				let d = new Date(date);
				return d.getUTCDate() + '-' + (d.getUTCMonth() + 1) + '-' + d.getUTCFullYear();
			},
			toggleShowFilters() {
				this.showFilters = !this.showFilters;
			}
        },
    }
</script>

<style>
    .vs__search {
        padding-left: 0px !important;
    }

    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        opacity: 0.5 !important;
        font-weight: 400 !important;
        color: #0e1b42 !important;
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
        opacity: 0.5 !important;
        font-weight: 400 !important;
        color: #0e1b42 !important;
    }

    ::-ms-input-placeholder { /* Microsoft Edge */
        opacity: 0.5 !important;
        font-weight: 400 !important;
        color: #0e1b42 !important;
    }
</style>

