<template>
    <div class="container-fluid overflow-x-hidden">
        <div class="row">
            <div class="col-lg-6 block-left">
                <nav class="navbar lang-absolute-container">
                    <LanguageSelector/>
                </nav>

                <div class="block-left-content">
                    <router-link class="back-link" :to="{ name: 'MerchantSetupPage' }">
                        &lt; {{ $store.state.translations.translations['Back'] }}
                    </router-link>

                    <h1>
                        {{ $store.state.translations.translations['Reset password'] }}
                    </h1>

                    <span class="simple-text">
                        {{ $store.state.translations.translations['Enter a new password'] }}
                    </span>

                    <p v-if="isSaving">
                        {{ $store.state.translations.translations['Resetting password...'] }}
                    </p>

                    <p v-if="fatalErrors" class="my-4 text-danger">
                        {{ fatalErrors }}
                    </p>

                    <form action="#" class="form" v-if="!isSaving && !fatalErrors">
                        <div class="form-group">
                            <label for="password">
                                {{ $store.state.translations.translations['Password'] }}
                            </label>

                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="icon-icon-key"></span>
                                </div>

                                <input type="password" id="password" class="form-control password" v-model="user.password" required>

                                <div class="input-group-append">
                                    <span class="icon-icon-eye"></span>
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="repeat-password">
                                {{ $store.state.translations.translations['Repeat password'] }}
                            </label>

                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="icon-icon-key"></span>
                                </div>

                                <input type="password" id="repeat-password" class="form-control password" v-model="user.repeatPassword" required>

                                <div class="input-group-append password">
                                    <span class="icon-check" v-if="user.repeatPassword && user.password === user.repeatPassword"></span>
                                    <span class="icon-icon-eye"></span>
                                </div>
                            </div>
                        </div>

                        <p v-if="errors" class="text-danger">
                            {{ errors }}
                        </p>

                        <div class="d-flex justify-content-end">
                            <button type="button" name="button" class="btn btn-secondary md" @click="updatePassword()">
                                {{ $store.state.translations.translations['Update password'] }}
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            <div class="col-6 block-right">

            </div>
        </div>
    </div>
</template>


<script>
    import LanguageSelector from '@/components/_LanguageSelector.vue';

    export default {
        components: {
            'LanguageSelector': LanguageSelector,
        },
        data() {
            return {
                user: {
                    password: '',
                    repeatPassword: ''
                },
                isSaving: false,
                hasSaved: false,
                errors: '',
                fatalErrors: '',
            }
        },
        created() {
            const url = new URL(this.$store.state.portalUrl + 'user/resetPassword');
            url.searchParams.set('userId', this.$route.query.userId);
            url.searchParams.set('token', this.$route.query.token);

            this.$http.get(url.href).then((data) => {
                this.fatalErrors = data.data.data.fatalErrors;
            });
        },
        methods: {
            updatePassword() {
                const url = new URL(this.$store.state.portalUrl + 'user/updatePassword');
                url.searchParams.set('userId', this.$route.query.userId);
                url.searchParams.set('token', this.$route.query.token);

                const params = new URLSearchParams();
                params.append('user[password]', this.user.password);
                params.append('user[repeatPassword]', this.user.repeatPassword);

                this.isSaving = true;

                this.$http.post(url.href, params).then((data) => {
                    this.isSaving = false;

                    if (typeof data.data.data.errors !== 'undefined') {
                        this.errors = data.data.data.errors;
                    } else {
                        this.hasSaved = true;
                        this.next();
                    }
                });
            },
            next() {
                this.$router.push({name: 'MerchantSetupPage'});
            }
        }
    }
</script>

<style>

</style>
