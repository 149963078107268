<template>
    <div v-if="currentAlert && currentAlert.type && currentAlert.message">
        <div class="my-3 alert alert-dismissible" :class="'alert-' + currentAlert.type">
            <a href="#" @click.prevent="closeAlert()" class="close" data-dismiss="alert" aria-label="close">&times;</a>
            {{ currentAlert.message }}
        </div>
    </div>
</template>

<script>
    export default {
        props: ['alert'],
        data() {
            return {
                currentAlert: {},
            }
        },
        methods: {
            closeAlert() {
                this.currentAlert = {};
            },
        },
        watch: {
            alert(newAlert) {
                this.currentAlert = newAlert;
            }
        }
    }
</script>

<style scoped>

</style>
