<template>
    <div>
        <SspHeader activeMenuItem="ship"/>

        <main class="main">
            <section class="middle-section">
                <div class="container text-center">
                    <h1 class="mb-5">
                        {{ $store.state.translations.translations['We\'re sorry, we cannot create a shipping label for your return'] }}
                        {{ returnReference }}
                    </h1>

                    <ContentBlock class="mt-3" section="ShipSubmitErrorPage" defaultContent="Please contact customer support to continue the return process." :key="$store.state.translations.currentLanguage"/>
                </div>
            </section>
        </main>

        <SspFooter></SspFooter>
    </div>
</template>

<script>
    import SspHeader from '@/components/_Header.vue';
    import SspFooter from '@/components/_Footer.vue';
    import ContentBlock from '@/components/_ContentBlock.vue';

    export default {
        components: {
            'SspHeader': SspHeader,
            'SspFooter': SspFooter,
            'ContentBlock': ContentBlock,
        },
        data() {
            return {
                returnReference: this.$route.query.returnReference
            }
        },
    }
</script>

<style scoped>
    h1 {
        font-size: 28px;
    }
</style>
