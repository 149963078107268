<template>
    <div class="container-fluid overflow-x-hidden">
        <div class="row">
            <div class="col-lg-6 block-left">
                <nav class="navbar lang-absolute-container">
                    <LanguageSelector/>
                </nav>
                 <div class="block-left-content">
                    <router-link :to="{ name: 'Login' }">&lt; Back</router-link>
                    <a class="navbar-brand" href="#"><img class="img-fluid" src="images/logo.png" alt=""></a>
                    <h1>Welcome</h1>
                    <span class="simple-text">Create a new account to manage easily your returns</span>

                    <p v-if="savedUser">
                        successfully registered your account <br/>
                        redirecting to home in {{ redirectAmount }}...
                    </p>

                    <p v-if="savingUser">
                        registering your account ...
                    </p>

                    <form action="#" class="form" v-if="!savingUser">
                        <div class="form-group">
                            <label for="">Email</label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="icon-icon-mail"></span>
                                </div>
                                <input type="email" class="form-control" v-model="user.email">
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Password</label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="icon-icon-key"></span>
                                </div>
                                <input type="password" class="form-control password" v-model="user.password">
                                <div class="input-group-append">
                                    <span class="icon-icon-eye"></span>
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="">Repeat Password</label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="icon-icon-key"></span>
                                </div>
                                <input type="password" class="form-control password" v-model="user.repeatPassword">
                                <div class="input-group-append password">
                                    <span class="icon-check"></span>
                                    <span class="icon-icon-eye"></span>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex justify-content-end">
                            <button type="button" name="button" class="btn btn-secondary md" @click="register()">CREATE
                                ACCOUNT
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            <div class="col-6 block-right">

            </div>
        </div>
    </div>
</template>


<script>
    import LanguageSelector from '@/components/_LanguageSelector.vue';

    export default {
        components: {
            'LanguageSelector': LanguageSelector,
        },
        data() {
            return {
                user: {
                    email: '',
                    password: '',
                    repeatPassword: ''
                },
                savingUser: false,
                savedUser: false,
            }
        },
        created() {

        },
        methods: {
            register() {
                let url = this.$store.state.portalUrl + 'user/register';
                let params = new URLSearchParams();
                params.append('user[email]', this.user.email);
                params.append('user[password]', this.user.email);
                params.append('user[repeatPassword]', this.user.email);
                this.$http.post(url, params).then((data) => {
                    if (typeof data.data.data.errors !== 'undefined') {
                        console.log(data.data.data.errors);
                    } else {
                        this.savedUser = true;
                        this.$router.push({path: '/merchantSetupSelection'});
                    }
                });
            },
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>
