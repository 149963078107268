import axios from '../classes/Axios';
import _ from 'lodash';

const merchantSetupStore = {
    namespaced: true,
    state: {
        merchantSetups: null,
        additionalProductFields: {},
    },
    mutations: {
        setMerchantSetups(state, [mcps, language]) {
            if (_.isEmpty(state.merchantSetups)) {
                state.merchantSetups = {};
            }

            if (_.isEmpty(state.merchantSetups[language])) {
                state.merchantSetups[language] = mcps;
            }
        },
        // setLoaded(state, bool) {
        //     state.loaded = bool;
        // },
        clear(state) {
            state.merchantSetups = null;
            // state.loaded = false;
        }
    },
    actions: {
        getAll(storeArgObj) {
            return new Promise((resolve) => {
                if (storeArgObj.state.merchantSetups && storeArgObj.state.merchantSetups[this.state.translations.currentLanguage]) {
                    resolve(storeArgObj.state.merchantSetups[this.state.translations.currentLanguage]);
                } else {
                    let url = new URL(storeArgObj.rootState.portalUrl + 'merchantSetup/getAll');
                    axios.get(url.href).then((data) => {
                        let mcps = data.data.data.merchantSetupModels;
                        storeArgObj.commit('setMerchantSetups', [mcps, this.state.translations.currentLanguage]);
                        resolve(mcps);
                    });
                }
            });
        },
        // getAdditionalProductField(storeArgObj, merchantSetupId) {
            // let url = new URL(this.$store.state.portalUrl + '');
            // url.searchParams.set('returnId', this.returnId);
            // url.searchParams.set('productId', productId);
            //
            // this.$http.get(url.href).then((data) => {
            //     this.customAttributes = data.data.data.customAttributes;
            //     this.returnReasons = data.data.data.returnReasons;
            //     this.serviceRequests = data.data.data.serviceRequests;
            // });


            // return new Promise((resolve, reject) => {
            //     if(storeArgObj.state.additionalProductFields[merchantSetupId]) {
            //         resolve(storeArgObj.state.additionalProductFields[merchantSetupId]);
            //     } else {
            //         let url = new URL(storeArgObj.rootState.portalUrl + 'product/getAdditionalInfo');
            //         url.searchParams.set('merchantSetupId', merchantSetupId);
			//
            //         axios.get(url.href).then((data) => {
            //             console.log(data);
            //             //
            //             // let mcps = data.data.data.merchantSetupModels;
            //             //
            //             // let setObj = {
            //             //     returnId: returnId,
            //             //     orders: orders
            //             // };
            //             //
            //             // storeArgObj.commit('setMerchantSetups', mcps);
            //             // resolve(mcps);
            //         });
            //     }
            // });
        // },
    }
};
export default merchantSetupStore;
