<template>
    <div class="accordion">
        <div class="accordion-card">
            <div class="accordion-card-header">
                <div class="accordion-card-header-td first">
                    <template v-if="article.disableFromOrderConnector">
                        <b-button class="btn-link" style="min-height: 39px; min-width: 51px;" v-b-tooltip
                                  v-bind:title="$store.state.translations.translations['This product cannot be returned']">
                        </b-button>
                    </template>

                    <template v-else>
                        <b-button v-b-toggle="'accordion-' + index" class="btn-link">
                            <i class="icon-plus"></i>
                        </b-button>
                    </template>
                </div>

                <template v-if="showModelNumber !== false">
                    <div class="accordion-card-header-td second">
                        {{ article.ModelNumber }}
                    </div>

                    <div class="accordion-card-header-td third">
                        {{ article.Description }}
                    </div>

                    <div class="accordion-card-header-td forth">
                        {{ article.Quantity }}
                    </div>
                </template>

                <template v-else>
                    <div class="accordion-card-header-td lg">
                        {{ article.Description }}
                    </div>

                    <div class="accordion-card-header-td forth">
                        {{ article.Quantity }}
                    </div>
                </template>

                <div class="accordion-card-header-td first"></div>
            </div>

            <b-collapse :id="'accordion-' + index"
                        class="" aria-labelledby="headingOne"
                        data-parent="#accordion"
                        v-model="additionalInfoLoaded"
            >
                <div class="collapse-body">
                    <div class="card-body">
                        <ProductDetailsForm :params="params"
                                            :errors="errors"
                                            @productDetailsFormLoaded="loadedForm()"
                                            :getDetailsOnLoad="true"
                                            ref="productDetailsForm"
                                            :articleGroupId="article.ProductGroupId"
                                            :maxRequested="article.Quantity"
                                            :class="productSaving ? 'd-none' : 'd-block'"
                        />

                        <template v-if="productSaving">
                            <p>
                                {{ $store.state.translations.translations['Saving product...'] }}
                            </p>
                        </template>

                        <template v-else>
                            <button type="button" class="btn md btn-primary mt-3" v-on:click="saveProduct()" v-if="!isLoadingForm">
                                {{ $store.state.translations.translations['Add'] }}
                            </button>

                            <InputFieldError attribute="general" :errors="errors" />
                        </template>
                    </div>
                </div>
            </b-collapse>
        </div>
    </div>
</template>

<script>
    import {BButton, BCollapse, VBToggle} from 'bootstrap-vue-3';
    import ProductDetailsForm from './_ProductDetailsForm.vue';
    import _ from 'lodash';
    import InputFieldError from '@/components/_InputFieldError.vue';

    export default {
        props: [
            'article',
            'params',
            'index'
        ],
        components: {
            'b-button': BButton,
            'b-collapse': BCollapse,
            'ProductDetailsForm': ProductDetailsForm,
            'InputFieldError': InputFieldError,
        },
        directives: {
            'b-toggle': VBToggle,
        },
        data() {
            return {
                errors: {},
                productSaving: false,
                savingProductMessage: 'Saving product...',
                returnId: this.$route.query.returnId,
                orderId: this.$route.query.orderId,
                isLoading: false,
                isLoadingForm: true,
                additionalInfoLoaded: false,
                customAttributes: null,
                form: {},
            }
        },
        mounted() {


        },
        methods: {
            saveProduct() {
                let data = this.$refs.productDetailsForm.getData();
                this.productSaving = true;

                let url = new URL(this.$store.state.portalUrl + 'product/saveOC');
                url.searchParams.set('orderId', this.orderId);
                url.searchParams.set('index', this.index);

                let formData = new FormData();
                formData.append('Product[CUSTOM_ATTRIBUTE_OPTION]', 'SPP_PRODUCT_FEED');

                _.forOwn(data.attributes, function (value, key) {
                    if (value !== null) {
                        formData.append('Product[' + key + ']', value);
                    }
                });

                _.forOwn(data.customAttributes, function (value, key) {
                    if (value !== null) {
                        formData.append('Product[' + key + ']', value);
                    }
                });


                this.$http.post(url.href, formData).then((data) => {
                    this.productSaving = false;

                    if (data?.data) {
                        if (data.data.data.errors) {
                            this.errors = data.data.data.errors;
                        } else {
                            let product = data.data.data.product;
                            this.additionalInfoLoaded = false;
                            this.$store.commit('products/add', {orderId: this.orderId, product: product});

                            this.$store.commit('OC/clear');
                            this.$store.commit('OC/setLoaded', false);
                            this.$store.dispatch('OC/getAll', {returnId: this.returnId, orderId: this.orderId});
                        }
                    }
                });
            },
            loadedForm() {
                this.isLoadingForm = false;
            },
        },
        computed: {
            showModelNumber() {
                return this.$store.state.mcpModule?.merchantSetups?.[this.$store.state.translations?.currentLanguage]?.[this.$route.query?.merchantSetupId]?.parameters?.showModelNumber;
            }
        }
    }
</script>

<style scoped>

</style>
