<template>
    <div class="page">
        <SspHeader activeMenuItem="ship"/>

        <main class="main mb-5">
            <InstructionsSection contentBlockSection="ShipmentStatusPage"
                                 progressBarCurrentModule="ship"
                                 progressBarCurrentStep="createLabel"
            />

            <div class="container container-full-width-md">
                <div class="col-12 mb-4" v-if="payment">
                    <template v-if="payment.status === 'open'">
                        {{ $store.state.translations.translations['Your payment has not yet been marked as paid. If you\'ve already gone through the payment, it might take a moment before the status is updated. The data on this page will automatically refresh.'] }}
                    </template>

                    <template v-else-if="payment.status === 'paid'">
                        {{ $store.state.translations.translations['Payment complete!'] }}
                    </template>

                    <template v-else-if="payment.status === 'canceled'">
                        {{ $store.state.translations.translations['Payment canceled.'] }}
                    </template>

                    <template v-else-if="payment.status === 'expired'">
                        {{ $store.state.translations.translations['Payment expired.'] }}
                    </template>

                    <template v-else-if="payment.status === 'failed'">
                        {{ $store.state.translations.translations['Payment failed.'] }}
                    </template>

                    <template v-if="paymentFailedStatuses.includes(payment.status)">
                        <div class="container">
                            <div class="col-12 d-flex flex-column flex-md-row align-items-center justify-content-center pt-3 pt-md-4 mb-5">
                                <button type="button" name="button" class="btn md btn-secondary m-3" @click.prevent="previous()">
                                    {{ $store.state.translations.translations['Back'] }}
                                </button>
                            </div>
                        </div>
                    </template>
                </div>

                <div class="col-12" v-if="isLoadingPaymentStatus">
                    {{ $store.state.translations.translations['Loading payment status...'] }}
                </div>

                <div class="col-12" v-else-if="Object.keys(errors).length > 0">
                    <InputFieldError attribute="global" :errors="errors"/>
                </div>
            </div>
        </main>

        <SspFooter />
    </div>
</template>

<script>
    import SspHeader from '@/components/_Header.vue';
    import SspFooter from '@/components/_Footer.vue';
    import InstructionsSection from '@/components/_InstructionsSection.vue';
    import InputFieldError from '@/components/_InputFieldError.vue';

    export default {
        components: {
            'SspHeader': SspHeader,
            'SspFooter': SspFooter,
            'InstructionsSection': InstructionsSection,
            'InputFieldError': InputFieldError,
        },
        data() {
            return {
                returnId: this.$route.query.returnId,
                orderId: this.$route.query.orderId,
                merchantSetupId: this.$route.query.merchantSetupId,
                isLoadingPaymentStatus: false,
                errors: {},
                payment: null,
                paymentFailedStatuses: ['expired', 'canceled', 'failed'],
                refreshDelay: 5000,
                refreshCount: 0,
            };
        },
        created() {
            this.getPaymentStatus();
        },
        methods: {
            previous() {
                this.$router.push({
                    name: 'AddAddress',
                    query: {returnId: this.returnId, orderId: this.orderId, merchantSetupId: this.merchantSetupId}
                });
            },
            next() {
                this.$router.push({
                    name: 'ShipSubmit',
                    query: {
                        returnId: this.returnId,
                    }
                });
            },
            getPaymentStatus(refresh = false) {
                this.isLoadingPaymentStatus = true;

                const url = new URL(this.$store.state.portalUrl + 'payment/getPaymentStatus');
                url.searchParams.append('returnId', this.returnId);
                url.searchParams.append('refresh', refresh);

                this.$http.get(url.href).then((data) => {
                    this.isLoadingPaymentStatus = false;

                    if (data.data.data.errors) {
                        this.errors = {global: data.data.data.errors};
                    } else {
                        this.payment = data.data.data.payment;

                        if (this.payment.status === 'paid') {
                            this.next();
                        } else if (this.payment.status === 'open') {
                            setTimeout(() => {
                                this.refreshCount++;
                                let shouldRefresh = this.refreshCount > 1;
                                this.getPaymentStatus(shouldRefresh);
                            }, this.refreshDelay);
                        }
                    }
                });
            },
        },
        filters: {
            capitalize: function (value) {
                if (!value) {
                    return '';
                }

                value = value.toString();
                return value.charAt(0).toUpperCase() + value.slice(1);
            }
        }
    }
</script>

<style scoped>

</style>
