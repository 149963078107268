<template>
    <footer class="section-footer">
        <div class="section-footer--content" id="footer-content" ref="footerContent">

            <div class="container sm">
                <div class="row" v-if="contactType && contactType !== 'none' && contactType !== 'chat'">
                    <div class="col-12 text-center" v-html="$store.state.translations.translations['In case you have any questions you can always {contactLink}'].replace('{contactLink}', contactLink)"></div>
                </div>

                <div class="row justify-content-center powered-by">
                    {{ $store.state.translations.translations['Returnbird powered by 12Return'] }}
                </div>
            </div>

        </div>
    </footer>
</template>

<script>
    export default {
        data() {
            return {
                contactType: null,
                contactLink: null,
                contactMessage: null,
            }
        },
        created() {
            this.getContactLink();
        },
        mounted() {
            global.context.authorization.$on('switchedLanguage', () => {
                /* Make sure the message is fully translated when changing language. */
                this.getContactLink();
            });
        },
        methods: {
            getPortalContent(section) {
                if (this.$store.state.portalContent.content && this.$store.state.portalContent.content[this.$store.state.translations.currentLanguage] && this.$store.state.portalContent.content[this.$store.state.translations.currentLanguage][section] && this.$store.state.portalContent.content[this.$store.state.translations.currentLanguage][section].RTN_PCT_ACTIVE != 0) {
                    return {
                        'title': this.$store.state.portalContent.content[this.$store.state.translations.currentLanguage][section].RTN_PCT_TITLE,
                        'content': this.$store.state.portalContent.content[this.$store.state.translations.currentLanguage][section].RTN_PCT_CONTENT,
                    };
                }

                return false;
            },
            getContactLink() {
                if (this.$store.state.portal.attributes.RTN_PTL_CONTACT_TYPE) {
                    this.contactType = this.$store.state.portal.attributes.RTN_PTL_CONTACT_TYPE;

                    switch (this.contactType) {
                        case 'website':
                            if (this.$store.state.portal.attributes.RTN_PTL_CONTACT_WEBSITE && this.$store.state.portal.attributes.RTN_PTL_CONTACT_WEBSITE !== '') {
                                let contactWebsite = this.$store.state.portal.attributes.RTN_PTL_CONTACT_WEBSITE;

                                if (contactWebsite.indexOf('http') === -1) {
                                    contactWebsite = 'https://' + contactWebsite;
                                }

                                this.contactMessage = this.$store.state.translations.translations['Visit us'].toLowerCase();
                                this.contactLink = '<a href="' + contactWebsite + '" target="_blank" ref="btnContactUs">' + this.contactMessage + '</a>';
                            }
                            break;

                        case 'email':
                            if (this.$store.state.portal.attributes.RTN_PTL_CONTACT && this.$store.state.portal.attributes.RTN_PTL_CONTACT !== '') {
                                let contactEmail = this.$store.state.portal.attributes.RTN_PTL_CONTACT;

                                if (contactEmail.indexOf('mailto') === -1) {
                                    contactEmail = 'mailto:' + contactEmail;
                                }

                                this.contactMessage = this.$store.state.translations.translations['Contact us'].toLowerCase();
                                this.contactLink = '<a href="' + contactEmail + '" target="_blank" ref="btnContactUs">' + this.contactMessage + '</a>';
                            }
                            break;

                        case 'chat':
                            this.createContactScript(this.$store.state.portal.attributes.RTN_PTL_CONTACT_JS_CODE);
                            break;
                    }
                }
            },
            extractScriptPropertiesAndContent(htmlString) {
                const parser = new DOMParser();
                const doc = parser.parseFromString(htmlString, 'text/html');
                const scriptTags = Array.from(doc.querySelectorAll('script'));

                const scriptsData = scriptTags.map((scriptTag) => {
                    const properties = {};
                    for (const attr of scriptTag.attributes) {
                        properties[attr.name] = attr.value;
                    }
                    const content = scriptTag.textContent;
                    return { properties, content };
                });

                return scriptsData;
            },
            createContactScript(source) {
                const scripts = this.extractScriptPropertiesAndContent(source);

                /* Check if the scripts haven't been added already. */
                if (!document.body.getAttribute('data-contact-scripts-loaded')) {
                    const scriptIsIntercom = source.indexOf('intercom') >= 0;
                    let scriptCount = 0;

                    /* Loop through the scripts in the given code. */

                    for(let i in scripts) {
                        let scr = scripts[i];

                        const scriptElement = document.createElement('script');
                        let scriptContent = scr.content;

                        /* If the script is for Intercom, replace the content of the second script element.
                         * In Intercom's default script the 'load' event listener is used to add Intercom to the page, but the script is added after the window.readyState is already completed.
                         * In the updated content below, Intercom will also added to the page when the window.readyState is already completed. */
                        if (scriptIsIntercom && scriptCount === 1) {
                            scriptContent = `(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + window.intercomSettings.app_id;var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`;
                        }


                        /* Loop through the script attributes from the given code and add them to the script element. */
                        for(let j in scr.properties) {
                            let prop = scr.properties[j];
                            scriptElement.setAttribute(j, prop);
                        }

                        /* Retrieve the script content from the given code and apply it to the script element. */
                        if (scriptContent) {
                            const content = document.createTextNode(scriptContent);
                            scriptElement.appendChild(content);
                        }

                        /* Add the script element to the body. */
                        document.body.appendChild(scriptElement);
                        scriptCount++;
                    }

                    /* No script tags were detected in the given code. Create a script with the given code as content. */
                    if (scriptCount === 0) {
                        const scriptElement = document.createElement('script');
                        const content = document.createTextNode(source);

                        scriptElement.appendChild(content);
                        document.body.appendChild(scriptElement);
                    }

                    /* Add the "data-contact-scripts-loaded" attribute to the body, which we use to check if the custom chat scripts have been loaded or not. */
                    document.body.setAttribute('data-contact-scripts-loaded', true);
                }
            }
        }
    }
</script>

<style scoped>
    @media (min-width: 768px) {
        .text-align-right-md {
            text-align: right;
        }
    }

    .section-footer {
        display: flex;
        flex-grow: 1;
    }

    .section-footer--content {
        background: #FCFDFD;
        border-top: 1px solid rgba(231, 233, 234, 0.45);
        padding: 10px 0;
        width: 100%;
        align-self: flex-end;
    }

    .section-footer--content a {
        font-size: 14px;
    }

    .section-footer--content p {
        margin-bottom: 5px;
    }

    .powered-by {
        color: #9b9b9b;
        font-size: 12px;
    }
</style>
