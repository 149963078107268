<template>
    <div class="steps-container">
        <div class="container container-full-width-md container-md-desktop">
            <ul class="steps">
                <li class="step-item hoverdisabled" v-for="(step, index) in this.steps" :key="index">
                    <a href="" v-if="index == currentStep" class="active step-link" @click.prevent="">
                        <i class="icon-parcel-icon"></i>

                        <span>
                            {{ $store.state.translations.translations[step] }}
                        </span>
                    </a>

                    <a href="" v-else class="step-link" v-on:click.prevent="">
                        <i class="icon-parcel-icon"></i>

                        <span>
                            {{ $store.state.translations.translations[step] }}
                        </span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['currentStep', 'currentModule', 'showDocuments', 'showBooking'],
        data() {
            return {
                steps: {}
            }
        },
        created() {
            if (this.currentModule === 'ship') {
                this.steps = {
                    'selectCarrier': 'Select carrier',
                    'createLabel': 'Create label',
                    'packAndShip': 'Pack & ship'
                };
            } else {
                this.steps = {
                    'order': 'Order number',
                    'addProducts': 'Add products',
                    'additionalInfo': 'Additional info',
                    'confirmation': 'Confirmation'
                };
            }
        },
        methods: {}
    }
</script>

<style>
    .hoverdisabled {
        pointer-events: none;
    }
</style>
